// Copyright © 2019 3D Robotics. All rights reserved.

/* eslint no-alert: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import CloudApi from '../../CloudApi';
import Button from '../Chrome/Elements/Button';
import Input from '../Chrome/Elements/Input';

class DuplicateProject extends React.Component {
  static propTypes = {
    projectId: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
  }

  handleClick = () => {
    const organizationId = this.input.value;
    if (!organizationId) {
      alert('Enter a valid organization ID');
      return;
    }

    this.aclRequest = CloudApi.get(`organizations/${organizationId}`);
    this.aclRequest.then((resp) => {
      const { acl } = resp.body;

      this.duplicateRequest = CloudApi.post(`projects/${this.props.projectId}/duplicate`, {
        newName: `${this.props.projectName} Copy`,
        org: organizationId,
        acl,
      });
      this.duplicateRequest
        .then((duplicateResponse) => {
          window.location.href = `/projects/${duplicateResponse.body.id}`;
        })
        .catch((duplicateError) => {
          alert(duplicateError);
        });
    }).catch((err) => {
      alert(err);
    });
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <Input placeholder="Organization ID" inputRef={(node) => { this.input = node; }} style={{ width: 142, margin: '4px 0' }} />
        <Button
          size="medium"
          onClick={this.handleClick}
        >
          Duplicate Project
        </Button>
      </div>
    );
  }
}

export default DuplicateProject;
