// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import OrganizationUpdateForm from './OrganizationUpdateForm';

export default function OrganizationEsri({ id, internalNotes }) {
  return (
    <OrganizationUpdateForm id={id}>
      <label>
        <div>Internal Notes</div>

        <textarea name="internalNotes" defaultValue={internalNotes} rows="10" />
      </label>
    </OrganizationUpdateForm>
  );
}
