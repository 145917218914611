// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from 'prop-types';
import { Component } from 'react';
import CloudApi from '../../CloudApi';

class UserFetch extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
  };

  state = {
    status: 'loading',
    user: undefined,
  };

  componentDidMount() {
    this.request = CloudApi.get(`users/${this.props.userId}`);

    this.request.then(
      (response) => {
        this.setState({
          status: 'loaded',
          user: response.body,
        });
      },
      (error) => {
        this.setState({
          status: error,
        });
      },
    );
  }

  componentWillUnmount() {
    this.request.abort();
  }

  render() {
    return this.props.children({ ...this.state });
  }
}

export default UserFetch;
