// Copyright © 2019 3D Robotics. All rights reserved.

import React from 'react';
import styles from './Table.module.scss';

const Table = (props) => {
  return (
    <table
      className={[props.className, styles.table].filter((x) => x).join(' ')}
    >
      {props.children}
    </table>
  );
};

export default Table;
