// Copyright © 2019 3D Robotics. All rights reserved.

import React from 'react';
import MainImage from '../../../Images/error.png';

const ErrorImage = ({ children }) => {
  
  return (
    <div style={{ margin: 'auto' }}>
      <img src={MainImage} alt="" />
      <div style={{ textAlign: 'center', width: '400px' }}>
        {children}
      </div>
    </div>
  );
}

export default ErrorImage
