// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import useCloudApi from '../../Utils/useCloudApi';
import DataProcesses from '../Status/DataProcesses';

export default function Mission({ missionId }) {
  const { data, isLoading, error } = useCloudApi(
    `missions/${missionId}/dataprocess`,
  );

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (data.length === 0) {
    return <p>There is no history for this mission.</p>;
  }

  return <DataProcesses processes={data} />;
}
