// Copyright © 2019 3D Robotics. All rights reserved.

import React, { useState, useEffect } from 'react';
import CloudApi from '../../CloudApi';
import styles from './OrganizationValidation.module.scss';

const OrganizationValidation = ({ orgId, hash }) => {
  const [validation, setValidation] = useState(null);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    validateOrganization(orgId);
  }, [orgId, hash]);

  const validateOrganization = (organizationId) => {
    const validationRequest = CloudApi.get(
      `organizations/${organizationId}/validate`,
    );

    validationRequest.then((resp) => {
      const validation = resp.body;

      const isValid =
        validation &&
        !validation.moreOperatorsThanLicenses &&
        !validation.moreUsersThanLicenses &&
        !validation.operatorsMustHaveWriteAccess.length > 0 &&
        !validation.projectPermissionsMustBeSubset.length > 0;

      setValidation(validation);
      setIsValid(isValid);
    });
  };

  if (isValid || !validation) {
    return null;
  }

  const {
    moreOperatorsThanLicenses,
    moreUsersThanLicenses,
    operatorsMustHaveWriteAccess,
    projectPermissionsMustBeSubset,
  } = validation;

  return (
    <section className={styles.messageBody}>
      <h1>Permission Errors - Action Required:</h1>

      <ul>
        {moreOperatorsThanLicenses && (
          <li>
            {`The number of operators cannot exceed the organization's operator license count.
                Please increase the operator license count or remove users as operators.`}
          </li>
        )}

        {moreUsersThanLicenses && (
          <li>
            {`The number of members with write or admin access cannot exceed the organization's
                license count. Please increase the license count or decrease user permissions to
                read-only.`}
          </li>
        )}

        {operatorsMustHaveWriteAccess.length > 0 && (
          <li>
            Operators must have at least write access on the organization. The
            following user(s) did not meet this criterion:
            <ul>
              {operatorsMustHaveWriteAccess.map((userId) => (
                <li key={`${userId}`}>{`User with id ${userId}`}</li>
              ))}
            </ul>
          </li>
        )}

        {projectPermissionsMustBeSubset.length > 0 && (
          <li>
            User permissions on projects cannot exceed their org permissions.
            Users also cannot belong to projects in the organization without
            belonging to the organization. The following users did not meet
            these criteria:
            <ul>
              {projectPermissionsMustBeSubset.map((item) => (
                <li key={`${item.project}-${item.user}`}>
                  {`User with id ${item.user} in project with id ${item.project}`}
                </li>
              ))}
            </ul>
          </li>
        )}
      </ul>
    </section>
  );
};

export default OrganizationValidation;
