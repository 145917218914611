// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import CloudApi from '../../CloudApi';

function ProjectPermissionSelect({ id, userId, acl, onChange }) {
  const updateACL = (event) => {
    const permission = event.target.value;

    CloudApi.put(`projects/${id}/users/${userId}`, { permission }).then(
      () => {
        // set ACL to reflect API
        onChange(
          acl.map((user) =>
            user.id === userId ? { ...user, permission } : user,
          ),
        );
      },
      () => {
        window.alert(
          'User permissions on project must not exceed their organization-level access. Visit the organization page to upgrade the user on the organization.',
        );
      },
    );
  };

  const item = acl.find((user) => user.id === userId);

  return (
    // support reading permissions with or without $ (Admin, Admin$)
    <select value={item.permission.replace('$', '')} onChange={updateACL}>
      <option value="Admin">Admin</option>
      <option value="Write">Write</option>
      <option value="Read">Read</option>
    </select>
  );
}

ProjectPermissionSelect.propTypes = {
  userId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  acl: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      permission: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ProjectPermissionSelect;
