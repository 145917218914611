// Copyright © 2019 3D Robotics. All rights reserved.

import React, { Component } from 'react';
import Button from '../Chrome/Elements/Button';
import CloudApi from '../../CloudApi';
import styles from './FirmwareEdit.module.scss';

class FirmwareEdit extends Component {
  static propTypes = {};

  state = {
    loadStatus: 'loading',
    saveStatus: 'saved',
    data: undefined,
  };

  componentDidMount() {
    this.reload();
  }

  componentWillUnmount() {
    this.request.abort();
  }

  reload() {
    this.setState({ loadStatus: 'loading' });
    this.request = CloudApi.get('firmware');

    this.request.then(
      (response) => {
        this.setState({
          loadStatus: 'loaded',
          saveStatus: 'saved',
          data: JSON.stringify(response.body, null, '\t'),
        });
      },
      (error) => {
        this.setState({
          loadStatus: error,
        });
      },
    );
  }

  jsonError() {
    try {
      JSON.parse(this.state.data);
      return null;
    } catch (e) {
      return e;
    }
  }

  save = () => {
    this.setState({ saveStatus: 'saving' });
    this.request = CloudApi.put('firmware', JSON.parse(this.state.data));

    this.request.then(
      response => this.setState({
        saveStatus: 'saved',
        data: JSON.stringify(response.body, null, '\t'),
      }),
      error => this.setState({ saveStatus: error }),
    );
  }

  handleChange = (event) => {
    this.setState({
      data: event.target.value,
      saveStatus: 'edited',
    });
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 9 || event.keyCode === 13) {
      event.preventDefault();
      const elem = event.target;
      const before = this.state.data.slice(0, elem.selectionStart);
      const after = this.state.data.slice(elem.selectionEnd);
      let insert = '';

      if (event.keyCode === 9) { // Make tab insert tabs
        insert = '\t';
      } else if (event.keyCode === 13) { // Make enter maintain indentation
        const match = before.match(/(\n[\t ]*)[^\n]*$/);
        insert = match ? match[1] : '\n';
      }

      const cursorPos = before.length + insert.length;

      this.setState(
        { data: `${before}${insert}${after}` },
        () => {
          elem.selectionStart = cursorPos;
          elem.selectionEnd = cursorPos;
        },
      );
    }
  }

  render() {
    const { loadStatus, saveStatus, data } = this.state;
    const error = this.jsonError();

    if (loadStatus === 'loaded') {
      return (
        <div className={styles.container}>
          <textarea value={data} onChange={this.handleChange} onKeyDown={this.handleKeyDown} />
          <div className={styles.buttonContainer}>
            <span className={styles.statusMessage}>{(error || '').toString() || saveStatus.toString()}</span>
            <Button onClick={this.save} disabled={error !== null || saveStatus === 'saved' || saveStatus === 'saving'}>Save</Button>
          </div>
        </div>
      );
    } else if (loadStatus === 'loading') {
      return <div>Loading...</div>;
    }
    return <div>There was an error loading the current configuration. {loadStatus.toString()}</div>;
  }
}

export default FirmwareEdit;
