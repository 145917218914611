// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NewUserError.module.scss';

export default function NewUserStatus({
  error,
  uploadError,
  status,
  organizationId,
}) {
  switch (status) {
    case 'loading':
      return <div>Submitting...</div>;

    case 'success':
      return (
        <div>
          Users created successfully!
          <Link to={organizationId ? `/organizations/${organizationId}` : '/'}>
            Return to the previous page
          </Link>
        </div>
      );

    case 'error':
      return (
        <div>
          <pre className={styles.error}>{error}</pre>
          <pre className={styles.error}>{uploadError}</pre>
        </div>
      );

    default:
      return null;
  }
}

NewUserStatus.propTypes = {
  error: PropTypes.string,
  uploadError: PropTypes.string,
  status: PropTypes.oneOf(['edit', 'loading', 'success', 'error']),
  organizationId: PropTypes.string,
};
