// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import { Icon, faCopy } from '../Components/Chrome/Elements/Icon';

export default function CopyToClipboard({ value }) {
  return (
    <button onClick={() => navigator.clipboard.writeText(value)}>
      <Icon icon={faCopy} />
    </button>
  );
}
