// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CloudApi from '../../CloudApi';
import styles from './OrganizationShareSetting.module.scss';

class OrganizationShareSetting extends Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    defaultChecked: PropTypes.bool,
  };

  static defaultProps = {
    defaultChecked: true,
  };

  state = {
    status: undefined,
    checked: this.props.defaultChecked,
  };

  componentWillUnmount() {
    if (this.request) {
      this.request.abort();
    }

    clearTimeout(this.timeout);
  }

  handleChange = (event) => {
    this.setState(({ checked }) => ({ status: 'loading', checked: !checked }));
    clearTimeout(this.timeout);

    this.request = CloudApi.put(`organizations/${this.props.organizationId}`, {
      shareLinkEnabled: event.target.checked,
    });

    this.request
      .then(
        () => {
          this.setState({ status: 'success' });
        },
        (error) => {
          this.setState(({ checked }) => ({
            status: error,
            checked: !checked,
          }));
        },
      )
      .then(() => {
        this.timeout = setTimeout(
          () => this.setState({ status: undefined }),
          1000,
        );
      });
  };

  render() {
    const { status, checked } = this.state;

    return (
      <div className={styles.setting}>
        <label>
          <input
            type="checkbox"
            checked={checked}
            onChange={this.handleChange}
          />

          <span>Public sharing enabled</span>

          {status === 'success' && (
            <span className={`${styles.message} ${styles.success}`}>saved</span>
          )}

          {status instanceof Error && (
            <span className={`${styles.message} ${styles.error}`}>
              {status.toString()}
            </span>
          )}
        </label>
      </div>
    );
  }
}

export default OrganizationShareSetting;
