// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Mission from '../Missions/Mission';

export default function MissionsRoute() {
  return (
    <Switch>
      <Route
        path="/missions/:missionId"
        render={({ match }) => <Mission missionId={match.params.missionId} />}
      />
    </Switch>
  );
}
