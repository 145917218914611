// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import { Link } from 'react-router-dom';
import useCloudApi from '../../Utils/useCloudApi';
import BodyChrome from '../Chrome/BodyChrome';
import RelatedLinks from '../Chrome/RelatedLinks';
import formatBytes from '../../Utils/formatBytes';
import { Icon, faDownload } from '../Chrome/Elements/Icon';

export default function DataProductPage({ dataProductId }) {
  const { data, isLoading, error } = useCloudApi(
    `dataproducts/${dataProductId}`,
  );

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <BodyChrome>
      <div>
        <h2>{data.name}</h2>
        <RelatedLinks>
          <Link to={`/missions/${data.parentId.id}`}>Mission</Link>
          <Link to={`/dataprocess/${data.fromProcess}`}>DataProcess</Link>
        </RelatedLinks>
        <p>Created {data.created}</p>
        <p>
          {data.contentLength && formatBytes(data.contentLength, 2)}{' '}
          {data.contentType} - {data.interpretation}
        </p>
        <p>{data.crs}</p>
        <p>
          <a href={data.url} target="_blank" rel="noreferrer">
            <Icon icon={faDownload} /> Download
          </a>
        </p>
        {data.related && data.related.thumb512 && (
          <img src={data.related.thumb512.url} alt="dataproduct preview" />
        )}
      </div>
    </BodyChrome>
  );
}
