// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import CloudApi from '../../CloudApi';
import ProjectAddUser from './ProjectAddUser';
import ChangeOrganization from './ChangeOrganization';
import ProjectBadge from './ProjectBadge';
import ProjectUsers from './ProjectUsers';
import DuplicateProject from './DuplicateProject';
import BodyChrome from '../Chrome/BodyChrome';
import styles from './ProjectInfo.module.scss';
import TabbedContent from '../Chrome/TabbedContent';
import ProjectMissions from './ProjectMissions';
import DeletedBanner from '../../Utils/DeletedBanner';

const ERRORS = {
  NONE: 'none',
  NOT_FOUND: 'not_found',
  PERMISSION: 'permission',
  OTHER: 'other',
};

const initialState = {
  loading: true,
  error: ERRORS.NONE,
  data: undefined,
};

class ProjectInfo extends React.Component {
  static propTypes = {
    projectId: PropTypes.string.isRequired,
  };

  state = {
    ...initialState,
  };

  componentDidMount() {
    this.requestUserData();
  }

  componentWillUnmount() {
    this.request = null; // TODO reject promise?
  }

  onNewProjectData = (response) => {
    // Success!
    if (response.status === 200) {
      this.setState({
        loading: false,
        data: response.body,
        error: ERRORS.NONE,
      });
    } else if (response.status === 404) {
      // Not Found
      this.setState({ loading: false, error: ERRORS.NOT_FOUND });
    } else if (response.status === 403) {
      // Unauthorized
      this.setState({ loading: false, error: ERRORS.PERMISSION });
    } else {
      // Other error
      this.setState({ loading: false, error: ERRORS.OTHER });
    }
  };

  requestUserData() {
    this.setState(initialState);
    this.request = CloudApi.get(`projects/${this.props.projectId}`);
    this.request.then(this.onNewProjectData);
    this.request.catch(this.onNewProjectData);
  }

  renderLoading = () => <div>Loading Project Data...</div>;

  renderError() {
    switch (this.state.error) {
      case ERRORS.NOT_FOUND:
        return <div>Error - The specified project does not exist</div>;
      case ERRORS.PERMISSION:
        return (
          <div>Error - You do not have permission to view this project</div>
        );
      case ERRORS.OTHER:
      default:
        return (
          <div>
            An unknown error occurred. Please check the console for more info.
          </div>
        );
    }
  }

  restoreProject() {
    CloudApi.post(`projects/${this.props.projectId}/restore`).then(() => this.requestUserData())
  }

  render() {
    if (this.state.loading) {
      return this.renderLoading();
    }

    if (this.state.error !== ERRORS.NONE) {
      return this.renderError();
    }

    const { projectId } = this.props;
    const projectPath = `/projects/${projectId}`;
    const missionsPath = `${projectPath}/missions`;
    const deletedMissionsPath = `${projectPath}/missions/deleted`;

    return (
      <BodyChrome>
        <div className={styles.header}>
          <ProjectBadge data={this.state.data} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ProjectAddUser
              projectId={projectId}
              organizationId={this.state.data.organizationId}
            />
            <DuplicateProject
              projectId={projectId}
              projectName={this.state.data.name}
            />
          </div>
          <ChangeOrganization
            projectId={projectId}
            organizationId={this.state.data.organizationId}
          />
        </div>

        { this.state.data.deletedAt && <DeletedBanner data={this.state.data} onRestore={() => this.restoreProject()} /> }

        <TabbedContent
          tabs={[
            {
              link: { to: projectPath, exact: true, children: 'Users' },
              route: {
                path: '/projects/:projectId',
                exact: true,
                render: () => <ProjectUsers project={this.state.data} />,
              },
            },
            {
              link: { to: missionsPath, exact: true, children: 'Missions' },
              route: {
                path: '/projects/:projectId/missions',
                exact: true,
                render: () => <ProjectMissions projectId={projectId} deleted={false} />,
              },
            },
            {
              link: { to: deletedMissionsPath, exact: true, children: 'Deleted Missions' },
              route: {
                path: '/projects/:projectId/missions/deleted',
                exact: true,
                render: () => <ProjectMissions projectId={projectId} deleted={true} />,
              },
            },
          ]}
        />
      </BodyChrome>
    );
  }
}

export default ProjectInfo;
