import { get, map } from 'lodash';
import moment from 'moment'
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import UserFetch from '../Users/UserFetch';
import UserDisplay from '../Users/UserDisplay';
import { Icon, faInfoCircle } from '../Chrome/Elements/Icon';

export default function DataProcessRow({process}) {
  return (
    <tr>
      <td>{process.processType}</td>
      <td style={{ fontSize: '85%' }}>
        <div>
          <Link to={`/dataprocess/${process.id}`}><code>{process.id}</code></Link>
        </div>
        <div>
          {get(process, 'parentId.kind') === 'mission' && (
            <code>
              <Link to={`/missions/${process.parentId.id}`}>
                {get(process, 'parentId.id')}
              </Link>
            </code>
          )}
        </div>
        <div>{process.name}</div>
      </td>
      <td>
        <UserFetch userId={process.createdBy}>
          {({ user }) => {
            if (!user) {
              return <div>...</div>;
            }

            return <UserDisplay user={user} />;
          }}
        </UserFetch>
      </td>
      <td>
        <ProcessSettings process={process} />
      </td>
      <td>
        <div>
          {moment(process.createdAt).format('lll')} (
          {moment(process.createdAt).fromNow()})
        </div>
        <div>
          {moment(process.updatedAt).format('lll')} (
          {moment(process.updatedAt).fromNow()})
        </div>
      </td>
      <td>
        <Status status={process.status} />
        <div>
          {process.statusMessage}
          {process.awsBatchIds && (
            <Link to={`/dataprocess/${process.id}/log`}>
              {' '}
              <Icon icon={faInfoCircle} />
            </Link>
          )}
        </div>
        <div style={{ color: 'red', 'lineBreak': 'anywhere' }}>{process.errorMsg}</div>
      </td>
    </tr>
  )
}

function Status({ status }) {
  const STATUS_COLORS = { pending: 'orange', error: 'red', complete: 'green' };

  return <div style={{ color: STATUS_COLORS[status] }}>{status}</div>;
}

function ProcessSettings({ process }) {
  const processSettings = get(process, `settings.${process.processType}`);
  const displayedSettings = [
    ['photoCount', 'Photos'],
    ['gcpTagsCount', 'GCPs'],
  ];
  return map(displayedSettings, ([key, name]) =>
    (processSettings && key in processSettings) && <div key={key}>{name}: {processSettings[key]}</div>
  )
}

ProcessSettings.propTypes = {
  process: PropTypes.shape({
    settings: PropTypes.shape({
      pix4d: PropTypes.shape({
        photosCount: PropTypes.number,
        gcpTagsCount: PropTypes.number,
      }),
    }),
  }).isRequired,
};
