// Copyright © 2021 Esri. All rights reserved.

import { get, map, forEach, round } from 'lodash';
import React, { useState } from 'react';
import formatError from '../../Utils/formatError';
import useCloudApi from '../../Utils/useCloudApi';
import { buildQueryParamsString } from '../../Utils/queryUtils';
import styles from './OrganizationPhotoUsage.module.scss';

function getDataInfo(data) {
  if (Array.isArray(data)) {
    let photos = 0;
    let bytes = 0;
    let pixels = 0;
    const start = get(data[0], 'start');

    forEach(data, (item) => {
      photos += get(item, 'photos');
      bytes += get(item, 'bytes');
      pixels += get(item, 'pixels');
    });

    return { photos, bytes, pixels, start };
  }
  return data;
}

function formatPhotos(photos) {
  if (!photos) {
    return 0;
  }

  return photos.toLocaleString();
}

function formatBytes(bytes) {
  if (bytes === 0) {
    return 0;
  }

  const gigabytes = round(parseFloat(bytes / Math.pow(1024, 3)), 2);

  return `${gigabytes.toLocaleString()} GB`;
}

function formatPixels(pixels) {
  if (pixels === 0) {
    return 0;
  }

  const megaPixels = round(parseFloat(pixels / 1000000), 2);

  return `${megaPixels.toLocaleString()} MP`;
}

const OrganizationPhotoUsage = ({ organizationId }) => {
  const [startDate, setStartDate] = useState();

  const dateNow = new Date().toISOString().substr(0, 10);
  const [endDate, setEndDate] = useState(dateNow);

  const [interval, setInterval] = useState('none');
  const intervals = ['none', 'year', 'quarter', 'month', 'week', 'day'];

  const params = {
    start: startDate,
    end: endDate,
    interval: interval === 'none' ? undefined : interval,
  };

  const queryParamsString = buildQueryParamsString(params);

  const { isLoading, data, error } = useCloudApi(
    `organizations/${organizationId}/usage/photos?${queryParamsString}`,
  );

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return (
      <p className={styles.error}>
        An error has occurred while fetching information about photo usage.{' '}
        {formatError(error)}
      </p>
    );
  }

  if (!data) {
    return null;
  }

  const { photos: photoCount, bytes, pixels, start } = getDataInfo(data);

  const handleStartDateKeyDown = (e) => {
    if (e.keyCode === 13) {
      setStartDate(e.target.value);
    }
  };

  const handleEndDateKeyDown = (e) => {
    if (e.keyCode === 13) {
      setEndDate(e.target.value);
    }
  };

  return (
    <div className={styles.container}>
      <p>
        Total Uploaded Photos: <b>{formatPhotos(photoCount)}</b>
      </p>

      <p>
        Total Uploaded Bytes: <b>{formatBytes(bytes)}</b>
      </p>

      <p>
        Total Uploaded Pixels: <b>{formatPixels(pixels)}</b>
      </p>

      <div className={styles.row}>
        <label htmlFor="start-date">Start Date: </label>
        <input
          type="date"
          id="start-date"
          defaultValue={startDate || start} // fall back to API start date if a user hasn't selected the value
          onBlur={(e) => setStartDate(e.target.value)}
          onKeyDown={handleStartDateKeyDown}
        />
      </div>

      <div className={styles.row}>
        <label htmlFor="end-date">End Date: </label>
        <input
          type="date"
          id="end-date"
          defaultValue={endDate}
          onBlur={(e) => setEndDate(e.target.value)}
          onKeyDown={handleEndDateKeyDown}
        />
      </div>

      <div className={styles.row}>
        <label htmlFor="interval">Interval: </label>
        <select
          id="interval"
          value={interval}
          onChange={(e) => setInterval(e.target.value)}
        >
          {map(intervals, (interval) => (
            <option value={interval} key={interval}>
              {interval}
            </option>
          ))}
        </select>
      </div>

      {interval !== 'none' && data && data.length !== 0 && (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Photos</th>
              <th>Bytes</th>
              <th>Pixels</th>
              <th>Start Date (UTC+0)</th>
            </tr>
          </thead>
          <tbody>
            {map(data, (item, index) => (
              <tr key={index}>
                <td>{formatPhotos(get(item, 'photos'))}</td>
                <td>{formatBytes(get(item, 'bytes'))}</td>
                <td>{formatPixels(get(item, 'pixels'))}</td>
                <td>{get(item, 'start')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {interval !== 'none' && data && data.length === 0 && (
        <p>No photos have been uploaded for this interval.</p>
      )}
    </div>
  );
};

export default OrganizationPhotoUsage;
