// Copyright © 2019 3D Robotics. All rights reserved.

import { map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CloudApi from '../../CloudApi';
import styles from './DataProcessLog.module.scss';

export default class DataProcessLog extends Component {
  /*
  just a wrapper that manages `startFromHead`, and delegates everything else to DataProcessLogInner.
  this design is mainly to ensure a fresh state when toggling `startFromHead`.
  */

  static propTypes = {
    dataProcessId: PropTypes.string.isRequired,
  };

  state = {
    startFromHead: false,
    complete: false,
  }

  setStartFromHead(newValue) {
    this.setState({
      startFromHead: newValue
    })
  }

  handleComplete() {
    this.setState({
      complete: true,
    })
  }

  render() {
    const setFromHead = (this.state.complete || this.state.startFromHead) ? null : () => this.setStartFromHead(true);
    const setFromTail = (this.state.complete || !this.state.startFromHead) ? null : () => this.setStartFromHead(false);
    return <div style={{ padding: '2em', }}>
      <DataProcessLogInner key={this.state.startFromHead} dataProcessId={this.props.dataProcessId}
        startFromHead={this.state.startFromHead}
        onComplete={() => this.handleComplete()}
        onSetFromHead={setFromHead}
        onSetFromTail={setFromTail}
        />
    </div>;
  }
}

class DataProcessLogInner extends Component {
  static propTypes = {
    dataProcessId: PropTypes.string.isRequired,
    startFromHead: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
    onSetFromHead: PropTypes.func,
    onSetFromTail: PropTypes.func,
  };

  state = {
    status: 'loading',
    logs: [],
    forwardToken: null,
    backwardToken: null,
  };

  componentDidMount() {
    this.reload();
  }

  componentWillUnmount() {
    this.request.abort();
  }

  appendNewLogs(isForward, newLogs) {
    this.setState((oldState, props) => {
        const logs = isForward
        ? oldState.logs.concat(newLogs)
        : newLogs.concat(oldState.logs);
        return {
          logs
        }
    });
  }

  onClickMoreButon(isForward, stateTokenKey) {
    this.request = CloudApi.post(`dataprocess/${this.props.dataProcessId}/logs`,this.state[stateTokenKey])
    this.setState(this.state[stateTokenKey])

    const newState = {
      status: 'loading',
    }
    newState[stateTokenKey] = null
    this.setState(newState)

    this.request.then((response) => {
      this.handleLogsResponse(isForward, response)
    })
  }

  moreButton(isForward) {
    const stateTokenKey = isForward ? 'forwardToken' : 'backwardToken'
    return <button
      enabled={(this.state.status!=='loading').toString()}
      onClick={() => this.onClickMoreButon(isForward, stateTokenKey)}>
      Load more
    </button>;
  }

  handleLogsResponse(isForward, response) {
    this.appendNewLogs(isForward, response.body.items)
    this.setState(state => {
      if (isForward) {
        Object.assign(state, {forwardToken: response.body.next})
      } else {
        Object.assign(state, {backwardToken: response.body.back})
      }
      Object.assign(state, {status: 'loaded'})

      if(!state.forwardToken && !state.backwardToken) {
        this.props.onComplete()
      }

      return state;
    })
  }

  reload() {
    this.setState({
      status: 'loading',
      forwardToken: null,
      backwardToken: null,
    });
    this.request = CloudApi.get(
      `dataprocess/${this.props.dataProcessId}/logs?startFromHead=${this.props.startFromHead}`
    );

    this.request.then((response) => {
        this.setState({logs: []});
        this.setState({
          forwardToken: response.body.next,
        })
        this.handleLogsResponse(this.props.startFromHead, response)
      },
      (error) => {
        this.setState({
          status: error,
        });
      },
    );
  }

  render() {
    const loadingStatus = this.state.status === 'loading' ? (
      <div>Loading...</div>
    ) : null

    return (
      <div style={{flex: 1, overflow: 'auto' }}>
        <span>
          {this.props.onSetFromHead ? <button onClick={this.props.onSetFromHead}>Jump to start</button> : null}
          {(this.state.backwardToken) ? this.moreButton(false) : null}
          {loadingStatus}
        </span>

        <div className={styles.logOutput} >
          {map(this.state.logs, (log, i) => {
            const key = this.props.startFromHead ? i : this.state.logs.length - i;

            return <div key={key}>
              {log.msg}
            </div>;
          })}
        </div>

        {this.state.status instanceof Error && (
          <div>There was an error retrieving logs. {this.state.status.toString()}</div>
        )}

        <span>
          {this.state.logs.length > 10 ? loadingStatus : null}
          {(this.state.forwardToken) ? this.moreButton(true) : null}
          {this.props.onSetFromTail ? <button onClick={this.props.onSetFromTail}>Jump to end</button> : null}
        </span>
      </div>
    );
  }
}