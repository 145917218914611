// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import UserBadge from './UserBadge';
import UserProjects from './UserProjects';
import UserOrganizations from './UserOrganizations';
import UserSessions from './UserSessions';
import BodyChrome from '../Chrome/BodyChrome';
import TabbedContent from '../Chrome/TabbedContent';
import { useUserContext } from './UserContext';

const UserInfo = () => {
  const { user, reloadUser } = useUserContext();

  const tabs = [
    {
      link: {
        to: `/users/${user.id}`,
        children: 'Projects',
        exact: true,
      },
      route: {
        path: '/users/:userId',
        exact: true,
        render: () => <UserProjects userId={user.id} />,
      },
    },
    {
      link: {
        to: `/users/${user.id}/organizations`,
        children: 'Organizations',
      },
      route: {
        path: '/users/:userId/organizations',
        render: () => <UserOrganizations user={user} />,
      },
    },
    {
      link: {
        to: `/users/${user.id}/sessions`,
        children: 'Sessions',
      },
      route: {
        path: '/users/:userId/sessions',
        render: () => <UserSessions userId={user.id} />,
      },
    },
  ];

  return (
    <BodyChrome>
      <UserBadge user={user} reloadUser={reloadUser} />
      <TabbedContent tabs={tabs} />
    </BodyChrome>
  );
};

export default UserInfo;
