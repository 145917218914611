// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import config from '../../config';

const ProjectBadge = ({ data }) => (
  <div>
    <h2>{data.name}</h2>
    <div>{moment(data.created).format('LLL')}</div>
    {!data.deletedAt &&
      <div>
          <a
              href={`${config.managerUrl}/projects/${data.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View in Manager
          </a>
      </div>
    }
  </div>
);

ProjectBadge.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProjectBadge;
