// Copyright © 2019 3D Robotics. All rights reserved.

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FirmwareEdit from '../Firmware/FirmwareEdit';

const FirmwareRoute = () => (
  <Switch>
    <Route path="/firmware/edit" component={FirmwareEdit} />
  </Switch>
);

export default FirmwareRoute;
