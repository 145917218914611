// Copyright © 2019 3D Robotics. All rights reserved.

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Status from '../Status/Status';

const StatusRoute = () => (
  <Switch>
    <Route path="/status/dataprocess/:processId" render={({ match }) => <Redirect to={`/dataprocess/${match.params.processId}`} />} />
    <Route path="/status" component={Status} />
  </Switch>
);

export default StatusRoute;
