// Copyright © 2019 3D Robotics. All rights reserved.

import React from 'react';
import styles from './BodyChrome.module.scss';

const BodyChrome = (props) => (
  <div className={styles.container}>
    {props.children}
  </div>
);

export default BodyChrome;
