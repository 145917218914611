// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from "prop-types";
import React from "react";

// Logo changes color depending on environment
const ENV_COLORS = {
  prod: "#48BFF6",
  staging: "#FFC564"
};

const ICON_WIDTH = 265;
const ICON_HEIGHT = 330;

function calculateDimensions(width, height) {
  // Assume only height is specified, scale width
  if (width === ICON_WIDTH && height !== ICON_HEIGHT) {
    return {
      width: ICON_WIDTH / ICON_HEIGHT * height,
      height
    };
  }

  // Assume only width is specified, scale height
  if (width !== ICON_WIDTH && height === ICON_HEIGHT) {
    return {
      width,
      height: width / (ICON_WIDTH / ICON_HEIGHT)
    };
  }

  return { width, height };
}

const SSAdminLogoFill = ({ width, height, color }) => (
  <svg
    {...calculateDimensions(width, height)}
    viewBox="0 0 265 330"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g>
        <g transform="translate(-1735 -1044)">
          <use
            xlinkHref="#a"
            transform="translate(1735 1044)"
            fill="url(#b)"
            fillOpacity=".8"
          />
        </g>
        <g transform="translate(-1735 -1044)">
          <use
            xlinkHref="#c"
            transform="rotate(180 999.75 687)"
            fill="url(#d)"
            fillOpacity=".8"
          />
        </g>
        <g transform="translate(-1735 -1044)">
          <use
            xlinkHref="#e"
            transform="rotate(180 997.5 685.75)"
            fill={color}
          />
        </g>
        <g transform="translate(-1735 -1044)">
          <use
            xlinkHref="#f"
            transform="rotate(180 999.25 686.75)"
            fill="url(#g)"
            fillOpacity=".9"
          />
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="b"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-126.15 .435 -.522 -151.436 190.925 89.11)"
      >
        <stop offset=".033" stopColor={color} />
        <stop offset="1" stopColor={color} stopOpacity=".7" />
      </linearGradient>
      <linearGradient
        id="d"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-96.588 192.88 -233.03 -116.694 345.85 85.216)"
      >
        <stop offset="0" stopColor={color} />
        <stop offset="1" stopColor={color} stopOpacity=".5" />
      </linearGradient>
      <linearGradient
        id="g"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(257.05 264.267 -211.654 205.875 112.277 -59.937)"
      >
        <stop offset="0" stopColor={color} />
        <stop offset=".972" stopColor={color} stopOpacity=".5" />
      </linearGradient>
      <path
        id="a"
        d="M87.5 219.5L250.466 16.256c5.25-6.546.59-16.256-7.8-16.256H10C4.478 0 0 4.477 0 10v150.866c0 3.497 1.827 6.74 4.82 8.553L87.5 219.5z"
      />
      <path
        id="c"
        d="M89.238 219.75L252.453 16.257c5.25-6.546.59-16.257-7.8-16.257H10.94C5.44 0 .973 4.44.94 9.94L.034 159.362c-.02 3.5 1.788 6.755 4.77 8.585L89.24 219.75z"
      />
      <path id="e" d="M172.5 107.5l13.5-17L0 0l172.5 107.5z" />
      <path
        id="f"
        d="M263.5 321.776v-152.22c0-3.456-1.783-6.666-4.716-8.49L6.114 3.806C3.45 2.146 0 4.06 0 7.2v151.263c0 3.445 1.773 6.647 4.693 8.476l252.684 158.224c2.664 1.668 6.123-.247 6.123-3.39z"
      />
    </defs>
  </svg>
);

SSAdminLogoFill.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

SSAdminLogoFill.defaultProps = {
  color: ENV_COLORS[process.env.REACT_APP_SSM_API_TARGET.split('-')[0]] || "#D13C2C",
  width: ICON_WIDTH,
  height: ICON_HEIGHT
};

export default SSAdminLogoFill;
