import React, { useState } from 'react';
import CloudApi from '../../CloudApi';
import Button from '../Chrome/Elements/Button';

// Render a button to either Resend Invite or Reset Password, depending on the user's "verified" status
// Users with "arcgisUsername" do not get a button. They must go through AGOL to reset their password.
export default function UserResetButton({ user }) {
  const [submitted, setSubmitted] = useState(false);

  if (user.arcgisUsername) {
    return null;
  }

  const resetPassword = () => {
    const encodedEmail = encodeURIComponent(user.email);
    CloudApi.post(`password/reset?email=${encodedEmail}`).then(() =>
      setSubmitted(true),
    );
  };

  let message;

  if (submitted) {
    message = 'Email Sent';
  } else if (user.verified) {
    message = 'Verified: Reset Password';
  } else {
    message = 'Not verified: Send Invite';
  }

  return (
    <Button onClick={resetPassword} size="medium" disabled={submitted}>
      {message}
    </Button>
  );
}
