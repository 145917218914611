// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import styles from './TabbedContent.module.scss';

const TabbedContent = ({ tabs }) => (
  <div className={styles.content}>
    <div className={styles.tabs}>
      {tabs.map(tab => (
        <NavLink {...tab.link} key={tab.link.to} activeClassName={styles.on} />
      ))}
    </div>

    {tabs.map((tab, index) => (
      <Route
        {...tab.route}
        key={tab.route.path}
        render={() => (
          <div
            className={
              index === 0 ? [styles.data, styles.first].join(' ') : styles.data
            }
          >
            {tab.route.render()}
          </div>
        )}
      />
    ))}
  </div>
);

TabbedContent.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        to: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
      }),
      route: PropTypes.shape({
        path: PropTypes.string.isRequired,
        render: PropTypes.func.isRequired,
      }),
    }),
  ).isRequired,
};

export default TabbedContent;
