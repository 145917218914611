// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import styles from './Button.module.scss';

const Button = ({
  children,
  className,
  modifiers,
  color,
  size,
  ...props
}) => {
  const styleNames = [styles.button];
  styleNames.push(styles[color]);
  styleNames.push(styles[modifiers]);
  styleNames.push(styles[size]);
  styleNames.push(className);
  if (props.disabled) {
    styleNames.push(styles.disabled);
  }

  return (
    <button {...props} className={styleNames.join(' ')}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  modifiers: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'inverted', 'secondary', 'danger', 'danger-inverted']),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Button.defaultProps = {
  children: undefined,
  className: undefined,
  modifiers: undefined,
  color: 'primary',
  disabled: false,
  size: 'large',
};

export default Button;
