// Copyright © 2022 Esri. All rights reserved.

import { sortBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CloudApi from '../../CloudApi';
import UserSessionItem from './UserSessionItem';

class UserSessions extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
  };

  state = {
    sessions: undefined,
    error: undefined,
  };

  componentDidMount() {
    this.requestUserSessions(this.props.userId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId !== prevProps.userId) {
      this.requestUserSessions(this.props.userId);
    }
  }

  componentWillUnmount() {
    this.request.abort();
  }

  requestUserSessions(userId) {
    this.setState({
      error: undefined,
    });

    this.request = CloudApi.get(`users/${userId}/sessions`);

    this.request.then(
      (response) => {
        this.setState({
          sessions: sortBy(response.body, [
            (session) => -moment(session.lastUsed).valueOf(),
          ]),
        });
      },
      (error) => {
        this.setState({
          error: error.response.text,
        });
      },
    );
  }

  revokeUserSession = (sessionId) => {
    this.revokeRequest = CloudApi.del(
      `users/${this.props.userId}/sessions/${sessionId}`,
    );

    return this.revokeRequest.then(
      () => this.requestUserSessions(this.props.userId),
      (error) => Promise.reject(error.response.text),
    );
  };

  render() {
    return (
      <div>
        {this.state.sessions && (
          <div>
            {this.state.sessions.map((session) => (
              <UserSessionItem
                key={session.id}
                session={session}
                onRevoke={this.revokeUserSession}
              />
            ))}
          </div>
        )}

        {this.state.error && <div>{this.state.error}</div>}
      </div>
    );
  }
}

export default UserSessions;
