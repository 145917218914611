// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import OrganizationUpdateForm from './OrganizationUpdateForm';

export default function OrganizationEsri({ id, esriCustomerNumber }) {
  return (
    <OrganizationUpdateForm id={id}>
      <label>
        <div>Esri Customer Number</div>
        <input name="esriCustomerNumber" defaultValue={esriCustomerNumber} />
      </label>
    </OrganizationUpdateForm>
  );
}
