// Copyright © 2022 Esri. All rights reserved.

import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import CloudApi from '../../CloudApi';
import { useAuthenticatedUserContext } from '../Auth/AuthenticatedUserContext';
import BackButton from '../Chrome/Elements/BackButton';
import Button from '../Chrome/Elements/Button';
import Field from '../Chrome/Elements/Field';
import Input from '../Chrome/Elements/Input';
import styles from './EditUser.module.scss';
import { useUserContext } from './UserContext';

export default function EditUser() {
  const { user, setUser } = useUserContext();

  const authenticatedUser = useAuthenticatedUserContext();

  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  const submit = useCallback(
    (event) => {
      event.preventDefault();

      const formData = new FormData(event.target);

      const data = {};

      for (const [key, value] of formData) {
        if (key === 'active') {
          data[key] = value === 'on';
        } else {
          data[key] = value;
        }
      }

      // formData will not contain checkbox values that are false
      // we need to explicitly set it
      if (!data.active) {
        data.active = false;
      }

      CloudApi.post(`users/${user.id}`, data).then(
        (response) => {
          setUser(response.body);
          setSuccess(response.body.id);
        },
        (error) => {
          const errorMessage =
            error.response.body.message || error.response.body.detail;
          if (errorMessage) {
            setError(errorMessage);
          } else {
            setError('An unknown error occurred. Check the console');
            console.error('An unknown error occurred: ', error); // eslint-disable-line no-console
          }
        },
      );
    },
    [setUser, user.id],
  );

  const isEditingSelf = user.id === authenticatedUser.id;

  if (success) {
    return <Redirect to={`/users/${success}`} />;
  }

  const { email, name, active } = user;

  return (
    <form className={styles.container} onSubmit={submit}>
      <h1>EDIT USER</h1>

      <Field title="Email">
        <Input
          name="email"
          defaultValue={email}
          type="email"
          className={styles.input}
        />
      </Field>

      <Field title="Full Name (Optional)">
        <Input name="name" defaultValue={name} className={styles.input} />
      </Field>

      <Field title="Active">
        <div className={styles['active-input']}>
          {isEditingSelf ? (
            <>
              <Input type="checkbox" defaultChecked disabled />
              <input type="hidden" name="active" value="on" />
              <p>You cannot deactivate yourself.</p>
            </>
          ) : (
            <Input name="active" type="checkbox" defaultChecked={active} />
          )}
        </div>
      </Field>

      <p>
        Changing <strong>email</strong> or <strong>active</strong> fields will
        log user out of all sessions.
      </p>

      {error && <p className={styles.error}>{error}</p>}

      <div className={styles.buttonContainer}>
        <Button>Save User</Button>

        <BackButton type="button" color="secondary">
          Cancel
        </BackButton>
      </div>
    </form>
  );
}
