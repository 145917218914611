// Copyright © 2019 3D Robotics. All rights reserved.

import React from 'react';
import Auth from '../Auth/Auth';

const AuthRoute = () => (
  <Auth />
);

export default AuthRoute;
