// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import Input from '../Chrome/Elements/Input';
import styles from './NewUserListItem.module.scss';

export default function NewUserListItem({ user, hidden, column }) {
  return (
    <tr className={hidden ? styles.hidden : undefined}>
      <td>
        <Input
          name="email"
          placeholder="Enter email..."
          defaultValue={user.email}
          type="email"
          pattern=".+\.[a-z]{2,}$" // must end in TLD
          title="Please enter a valid email address"
          required
        />
      </td>
      <td>
        <Input
          name={column}
          placeholder={`Enter ${
            column === 'arcgisUsername' ? 'ArcGIS username' : column
          }...`}
          defaultValue={user[column]}
          required
        />
      </td>
    </tr>
  );
}

NewUserListItem.propTypes = {
  user: PropTypes.shape().isRequired,
  hidden: PropTypes.bool,
  column: PropTypes.oneOf(['name', 'arcgisUsername']).isRequired,
};
