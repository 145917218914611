// Copyright © 2020 Esri. All rights reserved.

import React, { useRef, useState, useEffect } from 'react';
import CloudApi from '../../CloudApi';
import styles from './OrganizationOperatorLicenseModel.module.scss';

const OrganizationOperatorLicenseModel = ({
  organizationId,
  legacyOperatorLicenseModel,
}) => {
  const input = useRef();
  const timeout = useRef();
  const request = useRef();
  const [status, setStatus] = useState();
  const [isLegacy, setIsLegacy] = useState(true);

  useEffect(() => {
    setIsLegacy(legacyOperatorLicenseModel);
  }, [legacyOperatorLicenseModel]);

  // if user navigates away...
  // clear request in progress
  // clear status reset timer
  useEffect(
    () => () => {
      if (request.current) {
        request.current.abort();
      }
      clearTimeout(timeout.current);
    },
    [],
  );

  function handleChange(event) {
    // clear request in progress
    // clear status reset timer
    if (request.current) {
      request.current.abort();
    }
    clearTimeout(timeout.current);

    // remember target for after synthetic event goes away
    const legacyOperatorLicenseModel = input.current.checked;

    setStatus('loading');
    setIsLegacy(legacyOperatorLicenseModel);

    request.current = CloudApi.put(`organizations/${organizationId}`, {
      legacyOperatorLicenseModel,
    });

    request.current.then(
      () => {
        setStatus('success');
        timeout.current = setTimeout(() => setStatus(undefined), 1000);
      },
      (error) => {
        // reset to previous value
        setIsLegacy(!legacyOperatorLicenseModel);
        setStatus(error);
      },
    );
  }

  return (
    <div>
      <span>Use Legacy Operator License Model:</span>
      <input
        type="checkbox"
        checked={isLegacy}
        onChange={handleChange}
        ref={input}
      />

      {status === 'loading' && <span className={styles.message}>loading</span>}

      {status === 'success' && (
        <span className={`${styles.message} ${styles.success}`}>saved</span>
      )}

      {status instanceof Error && (
        <span className={`${styles.message} ${styles.error}`}>
          {status.toString()}
        </span>
      )}
    </div>
  );
};

export default OrganizationOperatorLicenseModel;
