// Copyright © 2021 Esri. All rights reserved.

import React from 'react';
import { Link, Route } from 'react-router-dom';
import Logout from '../Auth/Logout';
import SSAdminLogoStroke from '../Chrome/Elements/SSAdminLogoStroke';
import HeaderSearch from '../Search/HeaderSearch';
import styles from './HeaderChrome.module.scss';

const HeaderChrome = () => (
  <div className={styles.container}>
    <Link to="/">
      <SSAdminLogoStroke height={47} />
    </Link>

    <div className={styles.actions}>
      <Route
        path="/"
        render={({ match }) => !match.isExact && <HeaderSearch />}
      />

      <Link to="/status">Status</Link>

      <Link to="/reports">Reports</Link>

      <Logout />
    </div>
  </div>
);

export default HeaderChrome;
