// Copyright © 2019 3D Robotics. All rights reserved.

import React from "react";
import { Route, Switch } from "react-router-dom";
import NewOrganization from "../Organizations/NewOrganization";
import Organization from "../Organizations/Organization";

const OrganizationsRoute = () => (
  <Switch>
    <Route path="/organizations/new" exact component={NewOrganization} />
    <Route
      path="/organizations/:orgId"
      render={({ match }) => <Organization orgId={match.params.orgId} />}
    />
  </Switch>
);

export default OrganizationsRoute;
