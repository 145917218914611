// Copyright © 2022 Esri. All rights reserved.

import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import CloudApi from '../../CloudApi';
import ErrorImage from '../Chrome/Elements/ErrorImage';
import EditUser from './EditUser';
import { UserContext } from './UserContext';
import UserInfo from './UserInfo';

const ERRORS = {
  NONE: 'none',
  NOT_FOUND: 'not_found',
  PERMISSION: 'permission',
  OTHER: 'other',
};

export default function User({ userId }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(ERRORS.NONE);
  const [user, setUser] = useState();

  const reloadUser = () => {
    setLoading(true);
    setError(ERRORS.NONE);

    const request = CloudApi.get(`users/${encodeURIComponent(userId)}`);

    request
      .then(
        (response) => {
          setUser(response.body);
        },
        (error) => {
          switch (error.status) {
            case 404:
              setError(ERRORS.NOT_FOUND);
              break;

            case 403:
              setError(ERRORS.PERMISSION);
              break;

            default:
              setError(ERRORS.OTHER);
              console.error(
                'unexpected API response when requesting user data: ',
                error,
              );
              break;
          }
        },
      )
      .finally(() => setLoading(false));

    return () => {
      request.abort();
    };
  };

  useEffect(reloadUser, [userId]);

  if (loading) {
    return <div>Loading User Data...</div>;
  }

  switch (error) {
    case ERRORS.NOT_FOUND:
      return <ErrorImage>Error - The specified user does not exist</ErrorImage>;

    case ERRORS.PERMISSION:
      return (
        <ErrorImage>
          Error - You do not have permission to view user data
        </ErrorImage>
      );

    case ERRORS.OTHER:
      return (
        <ErrorImage>
          An unknown error occurred. Please check the console for more info.
        </ErrorImage>
      );

    default:
      break;
  }

  return (
    <UserContext.Provider value={{ user, setUser, reloadUser }}>
      <Switch>
        <Route path="/users/:id/edit">
          <EditUser />
        </Route>
        <Route path="/users/:id">
          <UserInfo />
        </Route>
      </Switch>
    </UserContext.Provider>
  );
}
