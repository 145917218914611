// Copyright © 2019 3D Robotics. All rights reserved.

import { sortBy, get } from 'lodash';

// Sort user list alphabetically: last name -> first name -> email
export default function sortUsers(permissions, users) {
  return sortBy(permissions, [
    ({ id }) => {
      const name = get(users[id], 'name');

      if (name) {
        // If user has name property, treat second word as last name. If only one word,
        // treat name property as first name.
        return name.toLowerCase().split(' ')[1] || name.toLowerCase();
      }

      return get(users[id], 'email', '').toLowerCase();
    },
  ]);
}
