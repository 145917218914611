// Copyright © 2019 3D Robotics. All rights reserved.

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "./Button";

class ButtonLink extends Component {
  handleClick = event => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }

    this.props.history.push(this.props.to);
  };

  render() {
    const passedProps = {...this.props};
    delete passedProps.match;
    delete passedProps.location;
    delete passedProps.history;
    delete passedProps.staticContext;

    return <Button {...passedProps} onClick={this.handleClick} />;
  }
}

export default withRouter(ButtonLink);
