// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import { Link } from 'react-router-dom';
import useCloudApi from '../../Utils/useCloudApi';
import Table from '../Chrome/Elements/Table';
import formatBytes from '../../Utils/formatBytes';
import { Icon, faDownload } from '../Chrome/Elements/Icon';

export default function DataProcessProducts({ dataProcess }) {
  const missionId = dataProcess.parentId.id;
  const {
    data: allDataProducts,
    isLoading,
    error,
  } = useCloudApi(`missions/${missionId}/dataproducts`);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const dataProducts = allDataProducts.filter(
    (d) => d.fromProcess === dataProcess.id,
  );

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Interpretation</th>
          <th>Size</th>
          <th>Download</th>
        </tr>
      </thead>
      <tbody>
        {dataProducts.map((product) => (
          <tr key={product.id}>
            <td>
              <Link to={`/dataproducts/${product.id}`}>{product.name}</Link>
            </td>
            <td>{product.contentType}</td>
            <td>{product.interpretation}</td>
            <td>{product.contentLength && formatBytes(product.contentLength, 2)}</td>
            <td>
              <a href={product.url} target="_blank" rel="noreferrer">
                <Icon icon={faDownload} /> Download
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
