// Copyright © 2019 3D Robotics. All rights reserved.

const mapboxToken = 'pk.eyJ1IjoiM2RyIiwiYSI6ImNpdG44MzR3eDAzcW8ydGw0ZzBhMWJ1bTMifQ.tq5vR5NsbJOx4Or6nhBKAQ';

const config = () => {
  switch (process.env.REACT_APP_SSM_API_TARGET) {
    case 'prod':
      return {
        dkcUrl: 'https://sitescan-api.arcgis.com',
        amplitudeApiKey: '7334e6b69a6d92305bce63f210da23d5',
        mapboxToken,
        managerUrl: 'https://sitescan.arcgis.com',
      };

    case 'prod-eu1':
      return {
        dkcUrl: 'https://sitescan-api-eu1.arcgis.com',
        amplitudeApiKey: '7334e6b69a6d92305bce63f210da23d5',
        mapboxToken,
        managerUrl: 'https://sitescan-eu1.arcgis.com',
      };

    case 'prod-india':
      return {
        dkcUrl: 'https://sitescan-api.arcgis.in',
        amplitudeApiKey: '7334e6b69a6d92305bce63f210da23d5',
        mapboxToken,
        managerUrl: 'https://sitescan.arcgis.in',
      };

    case 'staging':
      return {
        dkcUrl: 'https://api-staging.stscn.net',
        amplitudeApiKey: 'f3509fd737be9609fd6594427a06a1e4',
        mapboxToken,
        managerUrl: 'https://staging.stscn.net',
      };

    case 'staging-india':
      return {
        dkcUrl: 'https://api-staging-bharat.stscn.net',
        amplitudeApiKey: 'fca7cb0216cbb44cea44d374c47ffbee',
        mapboxToken,
        managerUrl: 'https://staging-bharat.stscn.net',
      };

    case 'local':
      return {
        dkcUrl: 'https://localhost:9000',
        amplitudeApiKey: 'fca7cb0216cbb44cea44d374c47ffbee',
        mapboxToken,
        managerUrl: 'https://localhost:8080',
      };

    case 'dev-eu1':
      return {
        dkcUrl: 'https://api-dev-eu1.stscn.net',
        amplitudeApiKey: 'fca7cb0216cbb44cea44d374c47ffbee',
        mapboxToken,
        managerUrl: 'https://dev-eu1.stscn.net',
      };

    case 'dev':
    default:
      return {
        dkcUrl: 'https://api-dev.stscn.net',
        amplitudeApiKey: 'fca7cb0216cbb44cea44d374c47ffbee',
        mapboxToken,
        managerUrl: 'https://master.dev-branch.stscn.net',
      };
  }
};

export default config();
