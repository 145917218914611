// Copyright © 2021 Esri. All rights reserved.

import { map, get, isEmpty } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import formatError from '../../Utils/formatError';
import useCloudApi from '../../Utils/useCloudApi';
import styles from './Reports.module.scss';

const Reports = () => {
  const { isLoading, data, error } = useCloudApi('adminReports');

  const reports = get(data, 'reports');

  return (
    <section className={styles.allReportsContainer}>
      <h1>Reports</h1>

      {isLoading && <p>Loading...</p>}

      {error && (
        <p className={styles.error}>
          An error has occurred while fetching reports. {formatError(error)}
        </p>
      )}

      {isEmpty(reports) && !isLoading ? (
        <p>Reports not found</p>
      ) : (
        <ul className={styles.list}>
          {map(reports, ({ id, name }) => (
            <li key={id}>
              <Link to={`reports/${id}`}>{name}</Link>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};

export default Reports;
