// Copyright © 2022 Esri. All rights reserved.

import React, { useMemo } from 'react';
import { Icon, faCheck, faEnvelope, faSitemap } from '../Chrome/Elements/Icon';

export default function OrganizationUserCompliance({ organization, user }) {
  const status = useMemo(() => {
    if (!organization || !user) {
      return;
    }

    if (!organization.arcgisOrganization) {
      return;
    }

    if (!user.arcgisOrganization) {
      return 'email';
    }

    if (user.arcgisOrganization !== organization.arcgisOrganization.id) {
      return 'organization';
    }

    return 'compliant';
  }, [organization, user]);

  switch (status) {
    case 'compliant':
      return (
        <Icon icon={faCheck} style={{ color: 'green' }} title="Compliant" />
      );

    case 'email':
      return (
        <Icon
          icon={faEnvelope}
          style={{ color: '#d13c2c' }}
          title="Email login"
        />
      );

    case 'organization':
      return (
        <Icon
          icon={faSitemap}
          style={{ color: '#d13c2c' }}
          title="Incorrect ArcGIS Organization"
        />
      );

    default:
      return <div />;
  }
}
