import React from 'react';
import { Link } from 'react-router-dom';
import { faBuilding, faUser, Icon } from '../Chrome/Elements/Icon';

export default function HeaderSearchResult({ result }) {
  return (
    <div>
      <Icon icon={result.type === 'user' ? faUser : faBuilding} />

      <Link to={getSearchResultPath(result)}>{result.name.trim()}</Link>

      {result.type === 'user' ? (
        <div>
          <div>{result.arcgisUsername}</div>
          <div>{result.email}</div>
        </div>
      ) : null}
    </div>
  );
}

function getSearchResultPath(result) {
  switch (result.type) {
    case 'user':
      return `/users/${result.userId}`;

    case 'organization':
      return `/organizations/${result.organizationId}`;

    default:
      throw new Error(`Unhandled search result type ${result.type}`);
  }
}
