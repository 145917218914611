// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import styles from './DataProcessTimeline.module.scss';

function formatDuration(ms) {
  if (ms < 60 * 1000) {
    return `${Math.round(ms / 1000)} seconds`;
  } else if (ms < 60 * 60 * 1000) {
    return `${Math.round(ms / 1000 / 60)} minutes`;
  } else {
    return `${Math.round(ms / 1000 / 60 / 60)} hours`;
  }
}

function DataProcessTimeline({ dataProcess }) {
  const formatter = new Intl.DateTimeFormat(undefined, {
    dateStyle: 'medium',
    timeStyle: 'medium',
  });

  const createdAt = new Date(dataProcess.createdAt);
  let prevTimestamp = createdAt;
  const entries = [];
  for (const { time: timeStr, statusMessage } of dataProcess.statusHistory) {
    const time = new Date(timeStr);
    const prevDuration = time - prevTimestamp;
    entries.push({ time, statusMessage, prevDuration });
    prevTimestamp = time;
  }
  const timeSinceLastUpdate =
    dataProcess.status === 'pending' ? new Date() - prevTimestamp : null;

  return (
    <div className={styles.timeline}>
      <time>{formatter.format(createdAt)}</time>
      <span className={styles.label}>Created</span>
      {entries.map(({ time, statusMessage, prevDuration }) => (
        <>
          <span className={styles.duration}>
            {formatDuration(prevDuration)}
          </span>
          <time>{formatter.format(time)}</time>
          <span className={styles.label}>{statusMessage}</span>
        </>
      ))}
      {timeSinceLastUpdate && (
        <span className={styles.duration}>
          {formatDuration(timeSinceLastUpdate)}
        </span>
      )}
    </div>
  );
}

export default function DataProcessDetails({ dataProcess }) {
  return (
    <div style={{ padding: '2em' }}>
      <h3>Timeline</h3>
      <DataProcessTimeline dataProcess={dataProcess} />

      <h3>Settings</h3>
      <pre>{JSON.stringify(dataProcess.settings, null, 2)}</pre>

      <h3>Results</h3>
      <pre>{JSON.stringify(dataProcess.results, null, 2)}</pre>
    </div>
  );
}
