// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import Button from './Button';
import styles from './Popup.module.scss';

const Popup = ({
  isOpen,
  header,
  message,
  onClose,
  onSubmit,
  actionLabel,
  error,
}) => (isOpen ? (
  <div className={styles.container}>
    <header>
      {header}
    </header>
    <section>
      {!error && message}
      <div className={styles.error}>{error}</div>
    </section>
    <footer>
      <Button color="secondary" onClick={onClose}>Cancel</Button>
      <Button onClick={onSubmit}>{actionLabel}</Button>
    </footer>
  </div>
) : null);

Popup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  actionLabel: PropTypes.string.isRequired,
  error: PropTypes.string,
};

Popup.defaultProps = {
  error: null,
};

export default Popup;
