// Copyright © 2022 Esri. All rights reserved.

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CloudApi from '../../CloudApi';
import Table from '../Chrome/Elements/Table';

export default function ProjectMissions({ projectId, deleted }) {
  const [missions, setMissions] = useState(null);

  useEffect(() => {
    const request = CloudApi.get(`projects/${projectId}/missions?deleted=${deleted}`);

    request.then((response) => {
      setMissions(response.body);
    });

    return () => {
      request.abort();
    };
  }, [projectId, deleted]);

  if (missions === null) {
    return <p>Loading...</p>;
  }

  if (missions.length === 0) {
    if (deleted) {
      return <p>This project has no recently-deleted missions.</p>;
    } else {
      return <p>This project has no missions.</p>;
    }
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>ID</th>
          <th>Created</th>
          { deleted && <th>Deleted</th> }
        </tr>
      </thead>
      <tbody>
        {missions.map(({ id, name, created, deletedAt }) => (
          <tr key={id}>
            <td>
              <Link to={`/missions/${id}`}>{name}</Link>
            </td>
            <td>
              <pre>{id}</pre>
            </td>
            <td>{moment(created).format('LLL')}</td>
            { deleted && <td>{moment(deletedAt).format('LLL')}</td> }
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
