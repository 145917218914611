// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import styles from './Label.module.scss';

const Label = ({
  className,
  ...props
}) => (
  <label {...props} className={([className, styles.label]).join(' ')} />
);

Label.propTypes = {
  className: PropTypes.string,
};

Label.defaultProps = {
  className: '',
};

export default Label;
