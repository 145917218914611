// Copyright © 2019 3D Robotics. All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Input.module.scss';

const Input = ({
  className,
  inputRef,
  ...props
}) => (
  <input
    className={([className, styles.input]).join(' ')}
    ref={node => inputRef && inputRef(node)}
    {...props}
  />
);

Input.propTypes = {
  className: PropTypes.string,
  inputRef: PropTypes.func,
};

Input.defaultProps = {
  className: undefined,
  inputRef: undefined,
};

export default Input;
