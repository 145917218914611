// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from "prop-types";
import React from "react";

// Logo changes color depending on environment
const ENV_COLORS = {
  production: "#60B2D8",
  staging: "#FFC564"
};

const ICON_WIDTH = 540;
const ICON_HEIGHT = 668;

function calculateDimensions(width, height) {
  // Assume only height is specified, scale width
  if (width === ICON_WIDTH && height !== ICON_HEIGHT) {
    return {
      width: ICON_WIDTH / ICON_HEIGHT * height,
      height
    };
  }

  // Assume only width is specified, scale height
  if (width !== ICON_WIDTH && height === ICON_HEIGHT) {
    return {
      width,
      height: width / (ICON_WIDTH / ICON_HEIGHT)
    };
  }

  return { width, height };
}

const SSAdminLogoStroke = ({ width, height, color }) => (
  <svg {...calculateDimensions(width, height)} viewBox="0 0 540 668" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>
      SS
    </title>
    <desc>
      Created using Figma
    </desc>
    <g>
      <g>
        <g transform="matrix(2 0 0 2 6 4)">
          <use xlinkHref="#a" fill="#FFF"/>
          <use xlinkHref="#b" fill={color}/>
        </g>
        <g transform="matrix(-2 0 0 -2 535 664)">
          <use xlinkHref="#c" fill="#FFF"/>
          <use xlinkHref="#d" fill={color}/>
        </g>
        <g transform="matrix(2 0 0 2 -2592 -2084)">
          <use xlinkHref="#e" transform="rotate(180 774.32 686.845)" fill={color} fillOpacity=".2"/>
        </g>
        <g transform="matrix(2 0 0 2 -2592 -2084)">
          <use xlinkHref="#f" transform="rotate(180 738.375 574.3)" fill={color} fillOpacity=".2"/>
        </g>
        <g transform="matrix(-2 0 0 -2 534.82 673.8)">
          <use xlinkHref="#g" fill="#FFF"/>
          <use xlinkHref="#h" fill={color}/>
        </g>
      </g>
    </g>
    <defs>
      <path id="a" d="M85.463 222.082L250.47 16.255C255.716 9.71 251.056 0 242.666 0H10C4.478 0 0 4.477 0 10v151.075c0 3.384 1.71 6.538 4.548 8.383l80.915 52.624z"/>
      <path id="b" d="M250.47 16.255l-1.56-1.25 1.56 1.25zM85.462 222.082l-1.09 1.677 1.517.98 1.13-1.417-1.56-1.25zM4.548 169.458l-1.09 1.676 1.09-1.676zM0 161.075h2-2zM0 10h2-2zm248.91 5.004L83.9 220.83l3.12 2.503L252.03 17.506l-3.12-2.502zM86.552 220.406L5.638 167.78l-2.18 3.354 80.914 52.625 2.18-3.36zM2 161.076V10h-4v151.075h4zM10 2h232.667v-4H10v4zm242.03 15.506C258.327 9.65 252.735-2 242.667-2v4c6.712 0 10.44 7.767 6.242 13.004l3.12 2.502zM5.638 167.78C3.368 166.306 2 163.783 2 161.076h-4c0 4.06 2.054 7.846 5.458 10.06l2.18-3.354zM2 10c0-4.418 3.582-8 8-8v-4C3.373-2-2 3.373-2 10h4z"/>
      <path id="c" d="M87.538 222.107L252.47 16.253C257.715 9.706 253.055 0 244.666 0H10.94C5.44 0 .973 4.44.94 9.94L.033 159.544c-.02 3.4 1.688 6.578 4.536 8.436l82.96 54.127z"/>
      <path id="d" d="M252.47 16.253l1.56 1.25-1.56-1.25zM87.538 222.107l-1.093 1.675 1.52.99 1.134-1.414-1.57-1.25zM4.568 167.98l1.094-1.674-1.093 1.675zm-4.535-8.435l2 .012-2-.012zM.94 9.94l-2-.013 2 .012zM250.91 15L85.977 220.857l3.12 2.5L254.032 17.504l-3.12-2.5zM88.63 220.432L5.663 166.306l-2.186 3.35 82.97 54.126 2.185-3.35zM2.034 159.557L2.94 9.952l-4-.025-.907 149.606 4 .024zM10.94 2h121.81v-4H10.94v4zm121.81 0h111.916v-4H132.75v4zm121.28 15.503C260.326 9.647 254.733-2 244.667-2v4c6.71 0 10.44 7.765 6.243 13.002l3.12 2.5zM5.663 166.306c-2.28-1.487-3.645-4.03-3.63-6.75l-4-.023c-.024 4.08 2.027 7.893 5.444 10.123l2.186-3.35zM2.94 9.952c.026-4.4 3.6-7.952 8-7.952v-4c-6.6 0-11.96 5.328-12 11.927l4 .025z"/>
      <path id="e" d="M164.153 107.005l9.167-12.634L0 0l164.153 107.005z"/>
      <path id="f" d="M0 1.628l164.684 102.885L1.284 0 0 1.628z"/>
      <path id="g" d="M264.36 326.036l.083-152.047c.002-3.39-1.704-6.54-4.536-8.39L16.13 6.59C10.88 3.17 3.81 5.362 1.42 11.156l-.214.52c-.196.476-.3.986-.302 1.502L.03 164.37c-.018 3.393 1.686 6.566 4.526 8.425L249.1 332.805c5.576 3.648 13.093.925 15.04-5.448l.046-.155c.116-.378.174-.77.175-1.166z"/>
      <path id="h" d="M264.443 173.99l-2-.002h2zm-.082 152.046h-2 2zM.03 164.37l-2-.013 2 .012zM.905 13.176l2 .01-2-.01zM264.187 327.2l-1.913-.583 1.913.584zm-.047.156l1.912.584-1.913-.583zm-15.04 5.447l1.095-1.673-1.095 1.674zM4.556 172.793L3.46 174.47l1.096-1.673zm-3.35-161.12l-1.848-.76 1.848.762zm.215-.52l1.85.764-1.85-.762zm14.71-4.56l-1.09 1.675 1.1-1.676zm243.78 159.014l-1.09 1.675 1.098-1.675zm2.54 8.38l-.08 152.047 4 .002.085-152.047-4-.01zM2.03 164.38l.875-151.19-4-.023-.873 151.192 4 .02zm260.244 162.24l-.047.155 3.826 1.167.047-.155-3.827-1.167zm-12.078 4.513L5.65 171.12l-2.19 3.348 244.544 160.01 2.19-3.347zM3.056 12.438l.214-.52-3.698-1.525-.214.52 3.697 1.525zm11.98-4.17l243.78 159.016 2.184-3.35L17.22 4.917l-2.184 3.35zM266.443 173.99c.003-4.057-2.045-7.84-5.443-10.057l-2.185 3.35c2.266 1.478 3.63 4 3.63 6.705l4 .002zm-4.082 152.045c.01.247-.01.348-.08.583l3.828 1.168c.154-.52.257-1.206.26-1.75h-4zM-1.97 164.357c-.022 4.074 2.022 7.88 5.43 10.11l2.19-3.346c-2.27-1.48-3.634-4.02-3.62-6.74l-4-.02zm4.874-151.17c-.007-.322.02-.454.15-.75L-.64 10.912c-.262.658-.44 1.545-.454 2.253l4 .023zm259.322 313.586c-1.557 5.098-7.57 7.276-12.03 4.358l-2.192 3.35c6.692 4.38 15.713 1.11 18.048-6.53l-3.826-1.166zM3.27 11.917c1.912-4.634 7.567-6.39 11.766-3.65l2.185-3.35C10.93.81 2.44 3.44-.42 10.392l3.698 1.525z"/>
    </defs>
  </svg>
);

SSAdminLogoStroke.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

SSAdminLogoStroke.defaultProps = {
  color: ENV_COLORS[process.env.REACT_APP_SSM_API_TARGET.split('-')[0]] || "#D13C2C",
  width: ICON_WIDTH,
  height: ICON_HEIGHT
};

export default SSAdminLogoStroke;
