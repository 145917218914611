// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Cropper from 'cropperjs';
import CloudApi from '../../CloudApi';
import '../../../node_modules/cropperjs/dist/cropper.css';

class Crop extends Component {
  static propTypes = {
    src: PropTypes.string,
    onCrop: PropTypes.func.isRequired,
  };

  static defaultProps = {
    // clear default image
    src: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
  };

  componentDidMount() {
    this.cropper = new Cropper(this.image, {
      viewMode: 1,
      aspectRatio: 1,
      crop: () => {
        this.cropper
          .getCroppedCanvas({ width: 100, height: 100 })
          .toBlob((blob) => {
            this.props.onCrop(blob);
          }, 'image/png');
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.cropper.replace(this.props.src);
    }
  }

  componentWillUnmount() {
    if (this.cropper) {
      this.cropper.destroy();
    }
  }

  setImage = (node) => {
    this.image = node;
  };

  render() {
    const { src, onCrop, ...rest } = this.props;

    return (
      <div {...rest}>
        <img
          src={this.props.src}
          ref={this.setImage}
          style={{ maxWidth: '100%' }}
          alt="cropper preview"
        />
      </div>
    );
  }
}

const actionStyles = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

// eslint-disable-next-line react/no-multi-comp
class OrganizationSetIcon extends Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    icon: PropTypes.string,
  };

  static defaultProps = {
    icon: undefined,
  };

  state = {
    src: undefined,
    croppedURL: this.props.icon,
    editing: false,
  };

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.croppedURL);

    if (this.saveRequest) {
      this.saveRequest.abort();
    }

    if (this.deleteRequest) {
      this.deleteRequest.abort();
    }
  }

  setFileInput = (node) => {
    this.fileInput = node;
  };

  setFile = (event) => {
    this.reader = new FileReader();

    this.reader.addEventListener(
      'load',
      () => {
        this.setState({ src: this.reader.result });
      },
      false,
    );

    this.reader.readAsDataURL(event.target.files[0]);
  };

  handleCrop = (blob) => {
    URL.revokeObjectURL(this.state.croppedURL);
    this.icon = blob;
    this.setState({
      croppedURL: URL.createObjectURL(blob),
    });
  };

  handleSave = () => {
    this.saveRequest = CloudApi.put(
      `organizations/${this.props.organizationId}/icon`,
      this.icon,
    );

    this.saveRequest.then(() => {
      this.setState({ editing: false });
    });
  };

  handleDelete = () => {
    if (!window.confirm('Are you sure you want to remove this icon?')) {
      return;
    }

    this.deleteRequest = CloudApi.del(
      `organizations/${this.props.organizationId}/icon`,
    );

    this.deleteRequest.then(() => {
      this.setState({ editing: false, src: undefined, croppedURL: undefined });
    });
  };

  render() {
    if (this.state.editing) {
      return (
        <div>
          <Crop
            src={this.state.src}
            onCrop={this.handleCrop}
            style={{ width: 400, height: 400, background: '#eee' }}
          />

          <div style={{ display: 'flex' }}>
            <div style={actionStyles}>
              <input
                type="file"
                onChange={this.setFile}
                accept="image/jpeg, image/png"
                ref={this.setFileInput}
              />

              <button
                onClick={() =>
                  this.setState({
                    editing: false,
                    croppedURL: this.props.icon,
                    src: undefined,
                  })
                }
                style={{ marginTop: 4 }}
              >
                Cancel
              </button>

              {this.state.croppedURL && (
                <button onClick={this.handleSave} style={{ marginTop: 4 }}>
                  Save New Icon
                </button>
              )}

              {this.state.croppedURL && this.props.icon && (
                <button onClick={this.handleDelete} style={{ marginTop: 4 }}>
                  Remove Current Icon
                </button>
              )}
            </div>

            {this.state.croppedURL && (
              <img
                src={this.state.croppedURL}
                alt="icon preview"
                style={{ border: '1px solid #eee', borderRadius: 10 }}
              />
            )}
          </div>
        </div>
      );
    }

    if (!this.state.croppedURL) {
      return (
        <button
          onClick={() =>
            this.setState({ editing: true }, () => this.fileInput.click())
          }
        >
          Upload Organization Icon
        </button>
      );
    }

    const iconStyle = {
      cursor: 'pointer',
      border: '1px solid #eee',
      borderRadius: 10,
      display: 'block',
    };

    return (
      <div style={{ textAlign: 'right' }}>
        <img
          src={this.state.croppedURL}
          alt="organization icon"
          onClick={() =>
            this.setState({ editing: true }, () => this.fileInput.click())
          }
          style={iconStyle}
        />
        <button onClick={this.handleDelete} style={{ marginTop: 4 }}>
          Remove Icon
        </button>
      </div>
    );
  }
}

export default OrganizationSetIcon;
