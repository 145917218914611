// Copyright © 2019 3D Robotics. All rights reserved.

import { get } from 'lodash';

/**
 * Normalizes API error. Prefers body.message > text
 * @param {Object} error - Error object from superagent API request
 * @returns {Promise} String representing error message from API.
 */
export default function formatError(error) {
  const response = get(error, 'response');
  const text = get(response, 'text');
  return get(response, 'body.message', text);
}
