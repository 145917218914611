// Copyright © 2022 Esri. All rights reserved.

import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NewUserList from './NewUserList';
import NewUserOptions from './NewUserOptions';
import NewUserStatus from './NewUserStatus';
import NewUserHeader from './NewUserHeader';
import Button from '../Chrome/Elements/Button';
import styles from './NewUserForm.module.scss';
import CloudApi from '../../CloudApi';

export const EMPTY_USER = {
  email: '',
  name: '',
  arcgisUsername: '',
};

export default function NewUserForm() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const organizationId = params.get('organizationId');

  const [users, setUsers] = useState([EMPTY_USER]);
  const [showHeaderRows, setShowHeaderRows] = useState(true);
  const [column, setColumn] = useState('name');
  const [error, setError] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState('edit');

  const handleUserUpload = (uploadedUsers) => {
    setUsers((prev) => [
      ...prev.filter((user) =>
        Object.values(user).some((value) => value !== ''),
      ),
      ...uploadedUsers,
    ]);
  };

  const handleAddUser = () => {
    setUsers((prev) => [...prev, EMPTY_USER]);
  };

  const handleUploadError = (error) => {
    setSubmissionStatus('error');
    setUploadError(error);
  };

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setSubmissionStatus('loading');

      const formData = new FormData(event.target);

      const emails = formData.getAll('email');
      const names = formData.getAll('name');
      const arcgisUsernames = formData.getAll('arcgisUsername');

      const users = [];

      for (let i = 0; i < emails.length; i += 1) {
        users[i] = {
          email: emails[i],
          permission: 'Read',
          projects: {},
          operator: false,
        };

        switch (column) {
          case 'name':
            users[i].name = names[i];
            break;

          case 'arcgisUsername':
            users[i].arcgisUsername = arcgisUsernames[i];
            users[i].name = arcgisUsernames[i];
            break;

          default:
            throw new Error(`Unhandled column type ${column}`);
        }
      }

      CloudApi.post(`organizations/${organizationId}/invite`, { users }).then(
        () => {
          setSubmissionStatus('success');
        },
        (error) => {
          setSubmissionStatus('error');
          setError(JSON.stringify(error.response.body, null, 2));
        },
      );
    },
    [organizationId, column],
  );

  return (
    <div className={styles.container}>
      <NewUserHeader organizationId={organizationId} />

      <form onSubmit={handleSubmit}>
        <NewUserList
          users={users}
          showHeaderRows={showHeaderRows}
          onToggleHeaderRows={() => setShowHeaderRows((prev) => !prev)}
          onColumnChange={setColumn}
          column={column}
        />

        <NewUserOptions
          onUserUpload={handleUserUpload}
          onUserAdd={handleAddUser}
          onUploadError={handleUploadError}
        />

        <NewUserStatus
          error={error}
          uploadError={uploadError}
          status={submissionStatus}
          organizationId={organizationId}
        />

        <Button
          type="submit"
          className={styles.button}
          disabled={submissionStatus === 'loading'}
        >
          Create Users
        </Button>
      </form>
    </div>
  );
}
