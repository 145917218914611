// Copyright © 2021 Esri. All rights reserved.

import React from 'react';
import CloudApi from '../../CloudApi';
import Button from '../Chrome/Elements/Button';
import UserSelect from '../Users/UserSelect';

class OrganizationAddUser extends React.Component {
  state = {
    error: undefined,
    user: undefined,
  };

  componentWillUnmount() {
    if (this.aclRequest) {
      this.aclRequest.abort();
    }

    if (this.updateAclRequest) {
      this.updateAclRequest.abort();
    }
  }

  onOrganizationUpdate = (response) => {
    this.setState({ error: undefined });
    window.location.reload();
  };

  updateOrganization = (acl) => {
    if (acl.find((item) => item.id === this.state.user.userId)) {
      this.setState({
        error: 'This user already has access to this organization.',
      });
      return null;
    }

    this.updateAclRequest = CloudApi.put(
      `organizations/${this.props.organizationId}/users/${this.state.user.userId}?add=true&validate=false`,
      { permission: 'Read' },
    );

    return this.updateAclRequest.then(this.onOrganizationUpdate);
  };

  handleError = (error) => {
    if (error.response) {
      console.error(error.response);
    } else {
      console.error(error);
    }
    const message = error && error.response && error.response.text;
    this.setState({ error: message });
  };

  submit = () => {
    this.aclRequest = CloudApi.get(
      `organizations/${this.props.organizationId}`,
    );

    this.aclRequest
      .then((response) => response.body.acl)
      .then(this.updateOrganization)
      .catch(this.handleError);
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }
    return <div>{this.state.error}</div>;
  }

  render() {
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <UserSelect onSelect={(user) => this.setState({ user })} />
          <Button
            disabled={!this.state.user}
            onClick={this.submit}
            size="medium"
          >
            Add Existing User
          </Button>
        </div>
        {this.renderError()}
      </div>
    );
  }
}

export default OrganizationAddUser;
