// Copyright © 2022 Esri. All rights reserved.

import { useState, useEffect } from 'react';
import CloudApi from '../CloudApi';

export default function useCloudApi(url) {
  const [isLoading, setIsLoading] = useState(url !== undefined);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  function reload() {
    if (!url) return;

    setIsLoading(true);
    setError(null);

    const request = CloudApi.get(url);

    request
      .then(
        (res) => setData(res.body),
        (err) => setError(err),
      )
      .finally(() => setIsLoading(false));

    return () => {
      request.abort();
    };
  }

  useEffect(reload, [url]);

  return { data, isLoading, error, reload };
}
