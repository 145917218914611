// Copyright © 2020 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import CloudApi from '../../CloudApi';
import OrganizationPermissionSelect from './OrganizationPermissionSelect';
import Table from '../Chrome/Elements/Table';
import RemoveUser from '../Users/RemoveUser';
import sortUsers from '../../Utils/sortUsers';
import OperatorStatus from '../Users/OperatorStatus';
import OrganizationUserCompliance from './OrganizationUserCompliance';

const shrinkCellStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '20vw',
};

const OrganizationUserTable = ({ organization, updateACL, onChange }) => {
  const [users, setUsers] = useState({});

  useEffect(() => {
    const requests = organization.acl.map(({ id }) =>
      CloudApi.get(`users/${id}`),
    );

    for (let request of requests) {
      request.then((response) => {
        const user = response.body;
        setUsers((prevUsers) => ({ ...prevUsers, [user.id]: user }));
      });
    }

    return () => {
      for (let request of requests) {
        request.abort();
      }
    };
  }, [organization.acl]);

  const sortedUsers = useMemo(() => {
    return sortUsers(organization.acl, users);
  }, [organization.acl, users]);

  const [operators, setOperators] = useState(organization.operators);

  const handleOperatorsChange = (arrayOfOperatorsIDs) => {
    setOperators(arrayOfOperatorsIDs);
    onChange(uuid());
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>ArcGIS Username</th>
          {organization.arcgisOrganization ? (
            <th style={{ textAlign: 'center' }}>Compliance</th>
          ) : null}
          <th style={{ textAlign: 'center' }}>Role</th>
          <th style={{ textAlign: 'center' }}>Operator</th>
          <th style={{ textAlign: 'center' }}>Manage</th>
        </tr>
      </thead>
      <tbody>
        {sortedUsers.map(({ id, permission }) => (
          <tr key={id}>
            <td style={shrinkCellStyle}>
              <Link to={`/users/${id}`}>{users[id] && users[id].name}</Link>
            </td>
            <td style={shrinkCellStyle}>{users[id] && users[id].email}</td>
            <td style={shrinkCellStyle}>
              {users[id] && users[id].arcgisUsername}
            </td>
            {organization.arcgisOrganization ? (
              <td style={{ textAlign: 'center' }}>
                <OrganizationUserCompliance
                  organization={organization}
                  user={users[id]}
                />
              </td>
            ) : null}
            <td style={{ textAlign: 'center' }}>
              <OrganizationPermissionSelect
                userId={id}
                organizationId={organization.id}
                permission={permission}
                onChange={updateACL}
              />
            </td>
            <td style={{ textAlign: 'center' }}>
              <OperatorStatus
                userId={id}
                id={organization.id}
                acl={organization.acl}
                operators={operators}
                onUpdateOperators={handleOperatorsChange}
              />
            </td>
            <td style={{ textAlign: 'center' }}>
              <RemoveUser
                userId={id}
                id={organization.id}
                type="organizations"
                acl={organization.acl}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

OrganizationUserTable.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    acl: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
    operators: PropTypes.arrayOf(PropTypes.string),
    operatorLicenseCount: PropTypes.number.isRequired,
    legacyOperatorLicenseModel: PropTypes.bool.isRequired,
    licenseCount: PropTypes.number.isRequired,
  }).isRequired,
  updateACL: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OrganizationUserTable;
