// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CloudApi from '../../CloudApi';
import styles from './OrganizationShareSetting.module.scss';

// Hardcoded feature list until API can provide one.
const FEATURES = {};

class OrganizationLicensedFeature extends Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    featureKey: PropTypes.string.isRequired,
    defaultValue: PropTypes.bool.isRequired,
  };

  state = {
    checked: this.props.defaultValue,
    status: undefined,
  };

  componentWillUnmount() {
    if (this.request) {
      this.request.abort();
    }

    clearTimeout(this.timeout);
  }

  toggleFeature = () => {
    if (this.request) {
      this.request.abort();
    }

    clearTimeout(this.timeout);

    this.request = CloudApi.put(`organizations/${this.props.organizationId}`, {
      licensedFeatures: {
        [this.props.featureKey]: !this.state.checked,
      },
    });

    this.request
      .then(
        () => {
          this.setState({ status: 'success' });
        },
        (error) => {
          this.setState(({ checked }) => ({
            status: error,
            checked: !checked,
          }));
        },
      )
      .then(() => {
        this.timeout = setTimeout(
          () => this.setState({ status: undefined }),
          1000,
        );
      });

    this.setState(({ checked }) => ({ checked: !checked, status: undefined }));
  };

  render() {
    const { featureKey } = this.props;
    const { checked, status } = this.state;

    return (
      <div className={styles.setting}>
        <label>
          <input
            type="checkbox"
            checked={checked}
            onChange={this.toggleFeature}
          />

          <span>{FEATURES[featureKey]}</span>

          {status === 'success' && (
            <span className={`${styles.message} ${styles.success}`}>saved</span>
          )}

          {status instanceof Error && (
            <span className={`${styles.message} ${styles.error}`}>
              {status.toString()}
            </span>
          )}
        </label>
      </div>
    );
  }
}

const OrganizationLicensedFeatures = ({ organizationId, features }) =>
  Object.keys(FEATURES).map((featureKey) => (
    <OrganizationLicensedFeature
      key={featureKey}
      organizationId={organizationId}
      featureKey={featureKey}
      defaultValue={!!features[featureKey]}
    />
  ));

export default OrganizationLicensedFeatures;
