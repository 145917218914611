// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import CloudApi from '../../CloudApi';

export const licenseTypeMap = {
  paidCustomer: 'Paid Customer',
  paidPoc: 'Paid Proof of Concept',
  freePoc: 'Free Proof of Concept',
  partner: 'Partner',
  internal: 'Internal',
  freeTrial: 'Free Trial',
};

export default function LicenseTypeDropdown({ licenseType, organizationId }) {
  const [selectKey, setSelectKey] = useState();

  function handleLicenseTypeChange(event) {
    const licenseType = event.target.value;

    // validate=false flag allows userAdmins (anyone with ss admin access) to update license counts
    const url = `organizations/${organizationId}?validate=false`;

    CloudApi.put(url, { licenseType })
      .then() // send request
      .catch((err) => {
        window.alert(`License Update Failed: ${err.message}`);
        setSelectKey(uuid()); // revert <select> to default value
      });
  }

  return (
    <label>
      <span>License Type: </span>
      <select
        key={selectKey}
        defaultValue={licenseType}
        onChange={handleLicenseTypeChange}
      >
        {Object.keys(licenseTypeMap).map((k) => (
          <option key={k} value={k}>
            {licenseTypeMap[k]}
          </option>
        ))}
      </select>
    </label>
  );
}

LicenseTypeDropdown.propTypes = {
  organizationId: PropTypes.string.isRequired,
  licenseType: PropTypes.string,
};
