// Copyright © 2020 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CloudApi from '../../CloudApi';
import Popup from '../Chrome/Elements/Popup';
import styles from './RemoveUser.module.scss';

class RemoveUser extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    acl: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
    type: PropTypes.oneOf(['projects', 'organizations']).isRequired,
    id: PropTypes.string.isRequired,
  };

  state = {
    isConfirmationOpen: false,
    error: null,
  };

  removeUser = () => {
    if (this.props.type === 'organizations' && !this.state.isConfirmationOpen) {
      this.setState({ isConfirmationOpen: true });
    } else {
      this.update();
    }
  };

  update = async () => {
    const { type, id, userId } = this.props;
    await CloudApi.del(`${type}/${id}/users/${userId}`);
    window.location.reload();
  };

  closeConfirmation = () =>
    this.setState({ isConfirmationOpen: false, error: null });

  render() {
    const className = [styles.link];

    return (
      <div>
        <Popup
          isOpen={this.state.isConfirmationOpen}
          header="Remove Member"
          message="Removing this user from the organization will also remove them from all projects within the organization."
          onClose={this.closeConfirmation}
          onSubmit={this.state.error ? this.closeConfirmation : this.removeUser}
          actionLabel={this.state.error ? 'Ok' : 'Remove Member'}
          error={this.state.error}
        />

        <button onClick={this.removeUser} className={className.join(' ')}>
          Remove
        </button>
      </div>
    );
  }
}

export default RemoveUser;
