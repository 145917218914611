// Copyright © 2021 Esri. All rights reserved.

import { map } from 'lodash';

export function buildQueryParamsString(queryParams) {
  const params = new URLSearchParams();

  map(queryParams, (value, key) => {
    if (value || typeof value === 'boolean') {
      params.append(key, value);
    }
  });

  return params.toString();
}
