// Copyright © 2019 3D Robotics. All rights reserved.

import React from 'react';
import { Link } from 'react-router-dom';
import CloudApi from '../../CloudApi';
import BackButton from '../Chrome/Elements/BackButton';
import Button from '../Chrome/Elements/Button';
import Field from '../Chrome/Elements/Field';
import Input from '../Chrome/Elements/Input';
import ErrorImage from '../Chrome/Elements/ErrorImage';
import styles from './NewOrganization.module.scss';

class NewOrganization extends React.Component {
  state = {
    name: '',
    error: undefined,
    addedOrganizations: [],
  };

  componentWillUnmount() {
    if (this.userRequest) {
      this.userRequest.abort();
    }

    if (this.createRequest) {
      this.createRequest.abort();
    }
  }

  handleSuccess = (response) => {
    this.setState({
      name: '',
      error: undefined,
      addedOrganizations: [response.body, ...this.state.addedOrganizations],
    });
  };

  handleError = (error) => {
    const errorMessage = error && error.response && error.response.text;
    if (errorMessage) {
      this.setState({ error: errorMessage });
    } else {
      this.setState({ error: 'An unknown error occurred. Check the console' });
      console.error('An unknown error occurred: ', error); // eslint-disable-line no-console
    }
  };

  submit = () => {
    // Use logged in user as the initial organization admin
    this.userRequest = CloudApi.get('users/me');
    this.userRequest.then((response) => {
      this.createRequest = CloudApi.post('organizations', {
        name: this.state.name,
        licenseCount: 0,
        acl: [
          {
            id: response.body.id,
            kind: 'user',
            permission: 'Admin$',
          },
        ],
      });
      this.createRequest.then(this.handleSuccess, this.handleError);
    });
  };

  // Organizations must have a non-blank name.
  validateForm = ({ name }) => !!(name && name.trim());

  render() {
    return (
      <div className={styles.container}>
        <h1>ADD NEW ORGANIZATION</h1>

        {this.state.addedOrganizations.length > 0 &&
          this.state.addedOrganizations.map((organization) => (
            <div key={organization.id}>
              Organization created! View the organization page for{' '}
              <Link to={`/organizations/${organization.id}`}>
                {organization.name}
              </Link>{' '}
              or add another.
            </div>
          ))}

        <Field title="Name">
          <Input
            value={this.state.name}
            onChange={(event) => this.setState({ name: event.target.value })}
            className={styles.input}
          />
        </Field>

        <div className={styles.buttonContainer}>
          <Button
            onClick={this.submit}
            disabled={!this.validateForm(this.state)}
          >
            Save
          </Button>
          <BackButton color="secondary">Cancel</BackButton>
        </div>

        {this.state.error && <ErrorImage>{this.state.error}</ErrorImage>}
      </div>
    );
  }
}

export default NewOrganization;
