// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const UserDisplay = ({ user }) => (
  <Fragment>
    <Link to={`/users/${user.id}`}>{user.name || user.email}</Link>
    <div>{user.email}</div>
    {user.arcgisUsername && user.arcgisUsername !== user.email && (
      <div>{user.arcgisUsername}</div>
    )}
  </Fragment>
);

UserDisplay.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string.isRequired,
    arcgisUsername: PropTypes.string,
  }).isRequired,
};

export default UserDisplay;
