// Copyright © 2022 Esri. All rights reserved.

import { get, sortBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import CloudApi from '../../CloudApi';
import RemoveUser from '../Users/RemoveUser';
import Table from '../Chrome/Elements/Table';

const initialState = {
  loading: true,
  projects: undefined,
  error: undefined,
};

class UserProjects extends React.Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
  };

  state = {
    ...initialState,
  };

  componentDidMount() {
    this.requestUserProjects(this.props.userId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userId !== this.props.userId) {
      this.requestUserProjects(this.props.userId);
    }
  }

  componentWillUnmount() {
    this.request.abort();
  }

  onRequestSuccess = (response) => {
    this.setState({
      loading: false,
      projects: sortBy(response.body, [(item) => item.name.toLowerCase()]),
    });
  };

  onRequestFailure = (error) => {
    this.setState({
      loading: false,
      error: get(error, 'response.body.error', error.toString()),
    });
  };

  requestUserProjects = (userId) => {
    this.setState(initialState);
    this.request = CloudApi.get(`users/${userId}/projects`);
    this.request.then(this.onRequestSuccess, this.onRequestFailure);
  };

  render() {
    if (this.state.loading) {
      return <div style={{ margin: '2em' }}>Loading...</div>;
    }

    if (this.state.error) {
      return <div style={{ margin: '2em' }}>{this.state.error}</div>;
    }

    if (this.state.projects && this.state.projects.length === 0) {
      return (
        <div style={{ margin: '2em' }}>
          This user does not belong to any projects.
        </div>
      );
    }

    return (
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>ID</th>
            <th>Number of Users</th>
            <th>Created</th>
            <th style={{ width: '200px' }}>Remove User</th>
          </tr>
        </thead>
        <tbody>
          {this.state.projects.map((project) => (
            <tr key={project.id}>
              <td>
                <Link to={`/projects/${project.id}`}>{project.name}</Link>
              </td>
              <td>
                <pre>{project.id}</pre>
              </td>
              <td>{project.acl.length}</td>
              <td>{moment(project.created).format('LLL')}</td>
              <td>
                <RemoveUser
                  userId={this.props.userId}
                  id={project.id}
                  type="projects"
                  acl={project.acl}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}
export default UserProjects;
