// Copyright © 2022 Esri. All rights reserved.

import { get, sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CloudApi from '../../CloudApi';
import Table from '../Chrome/Elements/Table';
import OrganizationPermissionSelect from '../Organizations/OrganizationPermissionSelect';
import OrganizationUserCompliance from '../Organizations/OrganizationUserCompliance';
import RemoveUser from '../Users/RemoveUser';
import OperatorStatus from './OperatorStatus';

export default function UserOrganizations({ user }) {
  const [organizations, setOrganizations] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);

    const request = CloudApi.get(`users/${user.id}/organizations`);

    request.then(handleSuccess, handleError).finally(() => setLoading(false));

    function handleSuccess(response) {
      setOrganizations(
        sortBy(response.body, [(org) => org.name.toLowerCase()]),
      );
    }

    function handleError(error) {
      setError(get(error, 'response.body.error', error.toString()));
    }

    return () => {
      request.abort();
    };
  }, [user.id]);

  if (loading) {
    return <div style={{ margin: '2em' }}>Loading...</div>;
  }

  if (error) {
    return <div style={{ margin: '2em' }}>{this.state.error}</div>;
  }

  if (organizations && organizations.length === 0) {
    return (
      <div style={{ margin: '2em' }}>
        This user does not belong to any organizations.
      </div>
    );
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th style={{ textAlign: 'center' }}>Compliance</th>
          <th style={{ textAlign: 'center' }}>Role</th>
          <th style={{ textAlign: 'center' }}>Operator</th>
          <th style={{ textAlign: 'right' }}>Number of Users</th>
          <th style={{ textAlign: 'center' }}>Remove User</th>
        </tr>
      </thead>
      <tbody>
        {organizations.map((organization) => (
          <UserOrganization
            key={organization.id}
            user={user}
            organization={organization}
          />
        ))}
      </tbody>
    </Table>
  );
}

function UserOrganization({ user, organization }) {
  const { id } = organization;

  const [acl, setAcl] = useState(organization.acl);
  const [operators, setOperators] = useState(organization.operators);

  const permission = acl.find(({ id }) => user.id === id).permission;

  return (
    <tr>
      <td>
        <Link to={`/organizations/${organization.id}`}>
          {organization.name}
        </Link>
      </td>

      <td style={{ textAlign: 'center' }}>
        <OrganizationUserCompliance organization={organization} user={user} />
      </td>

      <td style={{ textAlign: 'center' }}>
        <OrganizationPermissionSelect
          userId={user.id}
          organizationId={organization.id}
          permission={permission}
          onChange={setAcl}
        />
      </td>

      <td style={{ textAlign: 'center' }}>
        <OperatorStatus
          acl={acl}
          id={id}
          operators={operators}
          userId={user.id}
          onUpdateOperators={setOperators}
        />
      </td>

      <td style={{ textAlign: 'right', fontVariant: 'tabular-nums' }}>
        {organization.users.length.toLocaleString()}
      </td>

      <td style={{ textAlign: 'center' }}>
        <RemoveUser
          userId={user.id}
          id={organization.id}
          type="organizations"
          acl={organization.acl}
        />
      </td>
    </tr>
  );
}
