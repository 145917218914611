// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import Input from '../Chrome/Elements/Input';
import editIcon from '../../Images/edit.svg';
import CloudApi from '../../CloudApi';
import styles from './OrganizationName.module.scss';

class OrganizationName extends React.Component {
  state = {
    isEditing: false,
    name: this.props.name,
  }

  updateOrgName = () => {
    const { name } = this.state;

    if (name === '') {
      // If the input is empty, reset to the previous value
      return this.setState({ name: this.props.name, isEditing: false });
    }

    this.updateRequest = CloudApi.put(`organizations/${this.props.organizationId}?validate=false`, { name });
    return this.updateRequest.then(this.stopEditing);
  }

  startEditing = () => this.setState({ isEditing: true });

  stopEditing = (resp) => {
    if (resp.error) {
      return this.setState({ isEditing: false, name: this.props.name });
    }

    const { name } = resp.body;

    return this.setState({ isEditing: false, name });
  }

  handleNameChange = event => this.setState({ name: event.target.value });

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // Allow submission on enter press
      this.updateOrgName();
    }
  }

  render() {
    return this.state.isEditing ? (
      <Input
        className={styles.input}
        value={this.state.name}
        onChange={this.handleNameChange}
        onBlur={this.updateOrgName}
        onKeyDown={this.handleKeyDown}
        autoFocus
      />
    ) : (
      <h1 className={styles.name} onClick={this.startEditing}>
        {this.state.name}
        <img src={editIcon} className={styles.edit} alt="pencil" />
      </h1>
    );
  }
}

OrganizationName.propTypes = {
  name: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
};

export default OrganizationName;
