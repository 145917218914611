// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import { Link } from 'react-router-dom';
import useCloudApi from '../../Utils/useCloudApi';
import BodyChrome from '../Chrome/BodyChrome';
import TabbedContent from '../Chrome/TabbedContent';
import DataProcessProducts from './DataProcessProducts';
import DataProcessDetails from './DataProcessDetails';
import DataProcessLog from './DataProcessLog';
import RelatedLinks from '../Chrome/RelatedLinks';
import { get } from 'lodash';

export default function DataProcessPage({ dataProcessId }) {
  const { data, isLoading, error } = useCloudApi(
    `dataprocess/${dataProcessId}`,
  );

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const srcCameraProduct =
    get(data, 'settings.gcpDetect.cameraPose') ||
    get(data, 'settings.SURE.cameraOrientationsId');

  return (
    <BodyChrome>
      <div>
        <h2>
          {data.processType}: {data.name}
        </h2>
        <RelatedLinks>
          <Link to={`/missions/${data.parentId.id}`}>Mission</Link>
          {data.organizationId && (
            <Link to={`/organizations/${data.organizationId}`}>
              Organization
            </Link>
          )}
          <Link to={`/users/${data.createdBy}`}>User</Link>
          {srcCameraProduct && (
            <Link to={`/dataproducts/${srcCameraProduct}`}>Orientations</Link>
          )}
        </RelatedLinks>
        <p>Created {data.createdAt}</p>
        <p>
          <Status status={data.status} /> - {data.statusMessage}
        </p>
        {data.errorMsg && <p style={{ color: 'red' }}>{data.errorMsg}</p>}
      </div>

      <TabbedContent
        tabs={[
          {
            link: {
              to: `/dataprocess/${dataProcessId}`,
              exact: true,
              children: 'Details',
            },
            route: {
              path: '/dataprocess/:dataProcessId',
              exact: true,
              render: () => <DataProcessDetails dataProcess={data} />,
            },
          },
          {
            link: {
              to: `/dataprocess/${dataProcessId}/log`,
              exact: true,
              children: 'Log',
            },
            route: {
              path: '/dataprocess/:dataProcessId/log',
              exact: true,
              render: () => <DataProcessLog dataProcessId={dataProcessId} />,
            },
          },
          {
            link: {
              to: `/dataprocess/${dataProcessId}/dataproducts`,
              exact: true,
              children: 'Products',
            },
            route: {
              path: '/dataprocess/:dataProcessId/dataproducts',
              exact: true,
              render: () => <DataProcessProducts dataProcess={data} />,
            },
          },
        ]}
      />
    </BodyChrome>
  );
}

function Status({ status }) {
  const STATUS_COLORS = { pending: 'orange', error: 'red', complete: 'green' };
  return <span style={{ color: STATUS_COLORS[status] }}>{status}</span>;
}
