// Copyright © 2019 3D Robotics. All rights reserved.

import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Input from '../Chrome/Elements/Input';
import { Icon, faSpinner } from '../Chrome/Elements/Icon';
import CloudApi from '../../CloudApi';

class OperatorStatus extends React.Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onUpdateOperators: PropTypes.func.isRequired,
    acl: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
    operators: PropTypes.arrayOf(PropTypes.string), // won't exist if not yet set
  };

  static defaultProps = {
    operators: [],
  };

  state = {
    isLoading: false,
  };

  handleChange = (event) => {
    const operator = event.target.checked;
    this.setState({ isLoading: true });

    this.updateRequest = CloudApi.put(
      `organizations/${this.props.id}/users/${this.props.userId}?validate=false`,
      { operator },
    ).then(this.handleUpdateSuccess, this.handleUpdateError);
  };

  handleUpdateSuccess = (resp) => {
    // Update operators list to avoid having to fetch org list again
    const nextOperators = resp.body.operators;
    this.props.onUpdateOperators(nextOperators);
    this.setState({ isLoading: false });
  };

  handleUpdateError = (error) => {
    this.setState({ isLoading: false });
    window.alert(error.response.text); // eslint-disable-line no-alert
  };

  render() {
    const user = this.props.acl.find((item) => item.id === this.props.userId);
    const userPermission = get(user, 'permission');
    const isEligible = userPermission && userPermission !== 'Read$';
    const isActive = !!this.props.operators.find(
      (id) => id === this.props.userId,
    );

    if (!isEligible) {
      return null;
    }

    return this.state.isLoading ? (
      <Icon icon={faSpinner} spin />
    ) : (
      <Input type="checkbox" checked={isActive} onChange={this.handleChange} />
    );
  }
}

export default OperatorStatus;
