// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import { Link } from 'react-router-dom';
import CloudApi from '../../CloudApi';
import DeletedBanner from '../../Utils/DeletedBanner';
import useCloudApi from '../../Utils/useCloudApi';
import config from '../../config';
import BodyChrome from '../Chrome/BodyChrome';
import TabbedContent from '../Chrome/TabbedContent';
import MissionPhotos from './MissionPhotos';
import MissionProcessingHistory from './MissionProcessingHistory';

export default function Mission({ missionId }) {
  const { data, isLoading, error, reload } = useCloudApi(
    `missions/${missionId}`,
  );

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const missionPath = `/missions/${missionId}`;

  const dateTimeFormatter = new Intl.DateTimeFormat(undefined, {
    dateStyle: 'medium',
    timeStyle: 'medium',
  });

  function restoreMission() {
    CloudApi.post(`missions/${missionId}/restore`).then(() => reload());
  }

  return (
    <BodyChrome>
      <div>
        <h2>{data.name}</h2>
        {!data.deletedAt && (
          <a
            href={`${config.managerUrl}/projects/${data.projectId}/missions/${data.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View in Manager
          </a>
        )}
        <dl>
          <dt>Mission ID</dt>
          <dd>{data.id}</dd>
          <dt>Created</dt>
          <dd>{dateTimeFormatter.format(new Date(data.created))}</dd>
          <dt>Project ID</dt>
          <dd>
            <Link to={`/projects/${data.projectId}`}>{data.projectId}</Link>
          </dd>
        </dl>
      </div>

      {data.deletedAt && (
        <DeletedBanner data={data} onRestore={restoreMission} />
      )}

      <TabbedContent
        tabs={[
          {
            link: {
              to: missionPath,
              exact: true,
              children: 'Processing History',
            },
            route: {
              path: '/missions/:missionId',
              exact: true,
              render: () => <MissionProcessingHistory missionId={missionId} />,
            },
          },
          {
            link: {
              to: `${missionPath}/photos`,
              exact: true,
              children: 'Photos',
            },
            route: {
              path: '/missions/:missionId/photos',
              exact: true,
              render: () => <MissionPhotos missionId={missionId} />,
            },
          },
        ]}
      />
    </BodyChrome>
  );
}
