// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DataProductPage from '../DataProduct/DataProductPage';

export default function DataProductRoute() {
  return (
    <Switch>
      <Route
        path="/dataproducts/:dataProductId"
        render={({ match }) => (
          <DataProductPage dataProductId={match.params.dataProductId} />
        )}
      />
    </Switch>
  );
}
