// Copyright © 2021 Esri. All rights reserved.

import { useEffect, useState } from 'react';
import useCloudApi from '../../Utils/useCloudApi';
import { buildQueryParamsString } from '../../Utils/queryUtils';

const useSearch = ({ activeOnly, keyword, type }) => {
  const queryString = buildQueryParamsString({
    limit: 50,
    activeOnly,
    keyword,
    type,
  });

  return useDebouncedCloudApi(
    keyword ? `admin/search?${queryString}` : undefined,
  );
};

const useDebouncedCloudApi = (url, timeout = 300) => {
  const [debouncedUrl, setDebouncedUrl] = useState();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedUrl(url);
    }, timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [url, timeout]);

  return useCloudApi(debouncedUrl);
};

export default useSearch;
