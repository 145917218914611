// Copyright © 2020 Esri. All rights reserved.

import React, { useMemo, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import CloudApi from '../../CloudApi';
import { Icon, faSpinner, faCheck } from '../Chrome/Elements/Icon';
import styles from './DeactivationDateCalendar.module.scss';

function checkIfActive(active) {
  if (typeof active === 'boolean') {
    return active;
  }

  return new Date(get(active, 'until')) > new Date();
}

const DeactivationDateCalendar = ({ organizationId, date }) => {
  const [active, setActive] = useState(checkIfActive(date));
  const [dateError, setDateError] = useState(false);
  const [isSaving, setIsSaving] = useState();
  const [savedSuccessfully, setSavedSuccessfully] = useState();
  
  const updateOrganization = (data) => {
    const url = `organizations/${organizationId}`;
    const updateRequest = CloudApi.put(url, data);
    
    const isSavingDate = !!get(data, 'active.until');

    setIsSaving(isSavingDate); // set value only if date is being saved. Otherwise ignore this state
    setSavedSuccessfully();

    updateRequest
      .then((response) => {
        const active = get(response.body, 'active');

        setIsSaving(false);
        setSavedSuccessfully(isSavingDate); // set value only if date has been saved.
        setActive(checkIfActive(active));
      })
      .catch((err) => {
        setIsSaving(false);
        window.alert(`Update Failed: ${err.message}`);
      });
  };

  const resetDate = () => {
    const message =
      'Are you sure you want to make the organization permanantly active?';
    if (window.confirm(message)) {
      setSavedSuccessfully();
      updateOrganization({ active: true });
    }
  };

  const deactivateDate = () => {
    const message = 'Are you sure you want to deactivate the organization?';
    if (window.confirm(message)) {
      setSavedSuccessfully();
      updateOrganization({ active: false });
    }
  };

  // We use the date part of ISO format 2020-04-08T00:00:00Z
  const initialDate = get(date, 'until')
    ? get(date, 'until').split('T')[0]
    : undefined;

  const onInputBlur = (event) => {
    setSavedSuccessfully();
    
    if(event.target.value) {
      // event.target.value will be undefined if an incomplete date is in the input
      setDateError();
    } else {
      setDateError('invalid date');
      return;
    }
    
    // if no deactivation date, org is active forever
    const active = event.target.value
      ? { until: new Date(event.target.value).toISOString() }
      : true;

    updateOrganization({ active });
  };

  const statusClassName = useMemo(() => {
    if (active) {
      return `${styles.status} ${styles.active}`;
    }

    return `${styles.status} ${styles.inactive}`;
  }, [active]);

  return (
    <div className={styles.container}>
      <span className={styles.title}>Deactivation Date:</span>

      <input type="date" defaultValue={initialDate} onBlur={onInputBlur} className={dateError ? styles.dateError : ''} />
      
      {isSaving && <Icon icon={faSpinner} spin />}
      {savedSuccessfully && <Icon icon={faCheck} />}

      <div className={styles.actions}>
        <span className={statusClassName}>
          Organization Active: {active.toString()}
        </span>

        <div className={styles.buttonContainer}>
          <button onClick={resetDate}>Set Active</button>
          <button onClick={deactivateDate}>Set Inactive</button>
        </div>
      </div>
    </div>
  );
};

DeactivationDateCalendar.propTypes = {
  organizationId: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      until: PropTypes.string.isRequired,
    }),
  ]),
};

DeactivationDateCalendar.defaultProps = {
  date: undefined,
};

export default DeactivationDateCalendar;
