// Copyright © 2021 Esri. All rights reserved.

import React from 'react';
import HeaderChrome from './HeaderChrome';
import styles from './AuthenticatedChrome.module.scss';

export default function AuthenticatedChrome({ children }) {
  return (
    <div className={styles.container}>
      <HeaderChrome />
      {children}
    </div>
  );
}
