// Copyright © 2024 Esri. All rights reserved.

import React, { useRef, useState, useEffect } from 'react';
import CloudApi from '../../CloudApi';
import styles from './OrganizationOperatorLicenseModel.module.scss';

const Pix4dProcessingAllowance = ({ organizationId, allowEngineOverride }) => {
  const input = useRef();
  const timeout = useRef();
  const request = useRef();
  const [isEngineOverrideAllowed, setIsEngineOverrideAllowed] =
    useState(allowEngineOverride);
  const [status, setStatus] = useState();

  // if user navigates away,
  // clear request in progress and timer
  useEffect(
    () => () => {
      if (request.current) {
        request.current.abort();
      }
      clearTimeout(timeout.current);
    },
    [],
  );

  const handleChange = () => {
    // clear request in progress and timer
    if (request.current) {
      request.current.abort();
    }
    clearTimeout(timeout.current);

    const isChecked = input.current.checked;

    setStatus('loading');
    setIsEngineOverrideAllowed(isChecked);

    request.current = CloudApi.put(`organizations/${organizationId}`, {
      allowEngineOverride: isChecked,
    });

    request.current.then(
      () => {
        setStatus('success');
        timeout.current = setTimeout(() => setStatus(undefined), 1000);
      },
      (error) => {
        setIsEngineOverrideAllowed(!isChecked);
        setStatus(error);
      },
    );
  };

  return (
    <div style={{ paddingBottom: '10px' }}>
      <label htmlFor="pix4dProcessingAllowance">Allow Pix4D Processing:</label>

      <input
        type="checkbox"
        checked={isEngineOverrideAllowed}
        onChange={handleChange}
        id="pix4dProcessingAllowance"
        name="pix4dProcessingAllowance"
        ref={input}
      />

      {status === 'loading' && <span className={styles.message}>loading</span>}

      {status === 'success' && (
        <span className={`${styles.message} ${styles.success}`}>saved</span>
      )}

      {status instanceof Error && (
        <span className={`${styles.message} ${styles.error}`}>
          {status.toString()}
        </span>
      )}
    </div>
  );
};

export default Pix4dProcessingAllowance;
