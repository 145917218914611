// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import CloudApi from '../../CloudApi';
import Button from '../Chrome/Elements/Button';
import ButtonLink from '../Chrome/Elements/ButtonLink';
import styles from '../Chrome/BadgeChrome.module.scss';
import UserImpersonateButton from './UserImpersonateButton';
import CopyToClipboard from '../../Utils/CopyToClipboard';
import UserResetButton from './UserResetButton';

class UserBadge extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      verified: PropTypes.bool,
      admin: PropTypes.bool,
      userAdmin: PropTypes.bool,
      siteScanAdmin: PropTypes.bool,
    }).isRequired,
    reloadUser: PropTypes.func,
  };

  renderAdmin() {
    const enabled = {
      color: 'green',
    };
    const disabled = {
      color: 'grey',
      textDecoration: 'line-through',
    };

    return (
      <div className={styles.item}>
        <span
          style={this.props.user.userAdmin ? enabled : disabled}
          title="User has access to SSAdmin"
        >
          SSAdmin access
        </span>
      </div>
    );
  }

  unlinkArcGIS = () => {
    const { id } = this.props.user;

    const confirmationText =
      'Are you sure you want to unlink this user from ArcGIS?';

    if (!window.confirm(confirmationText)) {
      return;
    }

    CloudApi.post(`users/${id}/arcgisonline/unlink`).then(
      ({ body }) => {
        window.alert('User unlinked from ArcGIS Online.');
        this.props.reloadUser();
      },
      (error) => {
        window.alert(error.response.body.message);
      },
    );
  };

  clearEmailBounce = () => {
    const { id, email } = this.props.user;

    const confirmationText =
      `Have you confirmed that the email address '${email}' is valid to re-enable notifications?`;

    if (!window.confirm(confirmationText)) {
      return;
    }

    CloudApi.del(`users/${id}/emailBounce`).then(
      ({ body }) => {
        window.alert('Emails re-enabled.');
        this.props.reloadUser();
      },
      (error) => {
        window.alert(error.response.body.message);
      },
    );
  };

  render() {
    const { user } = this.props;
    const formatter = new Intl.DateTimeFormat(undefined, { dateStyle: 'medium' });
    return (
      <div>
        <h2 className={styles.title}>
          {user.name || user.email}
          {!user.active && ' (INACTIVE)'}
        </h2>
        {user.name && <div className={styles.item}>{user.email}</div>}
        {user.arcgisUsername && (
          <div className={styles.item}>
            ArcGIS Username: {user.arcgisUsername}
          </div>
        )}
        <div className={styles.item}>
          Site Scan ID: {user.id} <CopyToClipboard value={user.id} />
        </div>
        <div className={styles.item}>
          Member since: {formatter.format(new Date(user.createdAt))}
        </div>
        <div className={styles.item}>
          Last activity: {
            user.lastActivityAt
              ? formatter.format(new Date(user.lastActivityAt))
              : (user.verified ? "Unknown" : "Never")
          }
        </div>
        {user.emailBounce && (
          <div className={`${styles.item} ${styles.error}`}>
            Email notifications suspended for bounce since: {formatter.format(new Date(user.emailBounce))}
            {' '}
            <button onClick={this.clearEmailBounce}>
              Re-enable notifications
            </button>
          </div>
        )}

        {this.renderAdmin()}

        <ButtonLink to={`/users/${user.id}/edit`} size="medium">
          Edit User
        </ButtonLink>

        <UserResetButton user={user} />

        <UserImpersonateButton userId={user.id} />

        <Button
          onClick={this.unlinkArcGIS}
          size="medium"
          disabled={!this.props.user.arcgisUsername}
        >
          Unlink from ArcGIS Online
        </Button>
      </div>
    );
  }
}

export default UserBadge;
