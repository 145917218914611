// Copyright © 2019 3D Robotics. All rights reserved.

import request from 'superagent';
import { getToken, deleteToken } from './Components/Auth/Lock';
import config from './config';


/**
 * https://github.com/ArcGIS/sitescan-api/blob/dd20df4f0e7d77b511981ac65b2d4921eb7e64c2/src/main/scala/com/arcgis/sitescan/api/models/DataProcess.scala#L22
 */
export const dataprocessStatuses = [
  'created',
  'pending',
  'complete',
  'error',
  'cancelled',
]

function authorizedRequest(req, options = {}) {
  const token = getToken();
  // If Token is not valid, reload app (kicks user to login)
  if (!token) {
    window.location.reload(true);
  }

  if (options.headers) {
    req.set(options.headers);
  }

  req.set('Authorization', `Bearer ${token}`);
  
  req.on('error', (error) => {
    if (error.status === 401) {
      deleteToken();
      window.location.reload(true);
    }
  });
  return req;
}

class CloudApiClass {
  constructor(endpoint = `${config.dkcUrl}/api/v2/`) {
    this.endpoint = endpoint;
  }

  get(url, params) {
    const req = request.get(`${this.endpoint}${url}`);

    if (params) {
      req.query(params);
    }

    return authorizedRequest(req);
  }

  put(url, data, options = {}) {
    const req = request.put(`${this.endpoint}${url}`);

    if (data) {
      req.send(data);
    }

    return authorizedRequest(req, options);
  }

  post(url, data, options = {}, progressHandler) {
    const req = request.post(`${this.endpoint}${url}`);

    if (options.fields) {
      Object.keys(options.fields).forEach((key) => {
        req.field(key, options.fields[key]);
      });
    }

    if (options.attachments) {
      options.attachments.forEach((file) => {
        req.attach('file', file, file.name);
      });
    }

    if (data) {
      req.send(data);
    }

    if (progressHandler) {
      req.on('progress', progressHandler);
    }

    return authorizedRequest(req, options);
  }
  
  // Send POST request with Basic auth header
  basicPost(url, user, pass) {
    return request
      .post(`${this.endpoint}${url}`)
      .auth(user, pass);
  }

  del(url, options = {}) {
    const req = request.del(`${this.endpoint}${url}`);
    return authorizedRequest(req, options);
  }
}

const CloudApi = new CloudApiClass();

export default CloudApi;
