// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CloudApi from '../../CloudApi';
import Table from '../Chrome/Elements/Table';

class OrganizationProjectsTable extends Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    deleted: PropTypes.bool.isRequired,
  }

  state = {
    projects: undefined,
  };

  componentDidMount() {
    this.request = CloudApi.get(
      `organizations/${this.props.organizationId}/projects?deleted=${this.props.deleted}`,
    );

    this.request.then((response) => {
      const projects = this.sortProjects(response.body);
      this.setState({ projects });
    });
  }

  componentWillUnmount() {
    this.request.abort();
  }

  sortProjects = projectList => projectList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

  render() {
    return (
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>ID</th>
            <th>Number of Users</th>
            <th>Created</th>
            { this.props.deleted && <th>Deleted</th> }
          </tr>
        </thead>
        <tbody>
          {this.state.projects &&
            this.state.projects.map(project => (
              <tr key={project.id}>
                <td>
                  <Link to={`/projects/${project.id}`}>{project.name}</Link>
                </td>
                <td>
                  <pre>{project.id}</pre>
                </td>
                <td>{project.acl.length}</td>
                <td>{moment(project.created).format('LLL')}</td>
                { this.props.deleted && <td>{moment(project.deletedAt).format('LLL')}</td> }
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }
}

export default OrganizationProjectsTable;
