// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import BodyChrome from '../Chrome/BodyChrome';
import ButtonLink from '../Chrome/Elements/ButtonLink';
import Search from '../Search/Search';

const Home = () => (
  <BodyChrome>
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1>Atlas Home</h1>

        <div>
          <ButtonLink size="medium" to="/organizations/new">
            Add New Organization
          </ButtonLink>
        </div>
      </div>
    </div>

    <Search />
  </BodyChrome>
);

export default Home;
