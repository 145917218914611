import React from 'react';

export default function OrganizationArcGISOrganization({
  id = 'n/a',
  subscriptionId = 'n/a',
}) {
  return (
    <section style={{ marginBottom: '2em' }}>
      <header>ArcGIS Organization</header>
      <dl style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
        <dt>Organization ID</dt>
        <dd>{id}</dd>
        <dt>Subscription ID</dt>
        <dd>{subscriptionId}</dd>
      </dl>
    </section>
  );
}
