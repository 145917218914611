// Copyright © 2019 3D Robotics. All rights reserved.

import React from 'react';
import Lock from './Lock';
import styles from './Auth.module.scss';

class Auth extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <Lock />
      </div>
    );
  }
}

export default Auth;
