// Copyright © 2022 Esri. All rights reserved.

import React, { useCallback, useMemo, useState } from 'react';
import Select from 'react-select';
import useSearch from '../Search/useSearch';

const UserSelect = ({ onSelect }) => {
  const [keyword, setKeyword] = useState('');
  const handleChange = useCallback((value) => {
    setKeyword(value.trim());
  }, []);

  const params = useMemo(
    () => ({
      limit: 50,
      activeOnly: true,
      type: 'user',
      keyword,
    }),
    [keyword],
  );

  const { data } = useSearch(params);

  const options = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return data.map((user) => ({
      value: user,
      label:
        user.arcgisUsername === undefined
          ? user.email
          : `${user.email} (${user.arcgisUsername})`,
    }));
  }, [data]);

  return (
    <div style={{ minWidth: 250 }}>
      <Select
        placeholder="Search for user"
        onChange={({ value }) => onSelect(value)}
        onInputChange={handleChange}
        options={options}
      />
    </div>
  );
};

export default UserSelect;
