// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import CloudApi from '../../CloudApi';
import styles from './NewUserHeader.module.scss';

class NewUserHeader extends React.Component {
  static propTypes = {
    organizationId: PropTypes.string,
  };

  static defaultProps = {
    organizationId: undefined,
  };

  state = {
    organizationName: undefined,
  };

  componentDidMount() {
    if (this.props.organizationId) {
      this.fetchOrganizationName();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.organizationId && this.props.organizationId) {
      this.fetchOrganizationName();
    }
  }

  fetchOrganizationName() {
    CloudApi.get(`organizations/${this.props.organizationId}`).then(
      (response) => {
        if (response.body) {
          this.setState({ organizationName: response.body.name });
        }
      },
    );
  }

  render() {
    return (
      <header className={styles.header}>
        <span>Add New Users</span>
        {this.state.organizationName && (
          <span>{` to ${this.state.organizationName}`}</span>
        )}
      </header>
    );
  }
}

export default NewUserHeader;
