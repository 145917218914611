import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CloudApi from '../../CloudApi';
import styles from './OrganizationShareSetting.module.scss';

class OrganizationArcGIS extends Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    portalUrl: PropTypes.string,
    appId: PropTypes.string,
  };

  static defaultProps = {
    portalUrl: '',
    appId: '',
  };

  state = {
    status: undefined,
  };

  componentWillUnmount() {
    clearTimeout(this.statusTimeout);
  }

  submit = event => {
    event.preventDefault();

    const { organizationId } = this.props;

    const data = {
      arcGIS: {
        portalUrl: event.target.portalUrl.value,
        appId: event.target.appId.value,
      },
    };

    CloudApi.put(`organizations/${organizationId}`, data)
      .then(
        () => {
          this.setState({ status: 'success' });
        },
        () => {
          this.setState({ status: 'error' });
        },
      )
      .then(() => {
        this.statusTimeout = setTimeout(() => {
          this.setState({ status: undefined });
        }, 1000);
      });
  };

  render() {
    const { portalUrl, appId } = this.props;
    const { status } = this.state;

    return (
      <form onSubmit={this.submit} className={styles.form}>
        <header>Share to ArcGIS Enterprise Portal</header>

        <div className={styles.setting}>
          <label>Portal URL:</label>

          <input name="portalUrl" defaultValue={portalUrl} />
        </div>

        <div className={styles.setting}>
          <label>App ID:</label>

          <input name="appId" defaultValue={appId} />
        </div>

        <button>Save</button>

        {status === 'success' ? (
          <span className={`${styles.message} ${styles.success}`}>saved</span>
        ) : null}
        {status === 'error' ? (
          <span className={`${styles.message} ${styles.error}`}>error</span>
        ) : null}
      </form>
    );
  }
}

export default OrganizationArcGIS;
