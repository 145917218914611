// Copyright © 2021 Esri. All rights reserved.

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Report from '../Reports/Report';
import Reports from '../Reports/Reports';

const ReportsRoute = () => (
  <Switch>
    <Route
      path="/reports/:id"
      render={({ match }) => <Report id={match.params.id} />}
    />

    <Route path="/reports" component={Reports} />
  </Switch>
);

export default ReportsRoute;
