// Copyright © 2021 Esri. All rights reserved.

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NewUserForm from '../Users/NewUserForm';
import User from '../Users/User';

const UsersRoute = () => (
  <Switch>
    <Route path="/users/new" component={NewUserForm} />
    <Route
      path="/users/:userId"
      render={({ match }) => <User userId={match.params.userId} />}
    />
  </Switch>
);

export default UsersRoute;
