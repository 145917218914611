// Copyright © 2022 Esri. All rights reserved.

import React, { useContext } from 'react';

const AuthenticatedUserContext = React.createContext();

export function AuthenticatedUserProvider(props) {
  return <AuthenticatedUserContext.Provider {...props} />;
}

export function useAuthenticatedUserContext() {
  return useContext(AuthenticatedUserContext);
}
