// Copyright © 2019 3D Robotics. All rights reserved.

import React from 'react';
import styles from './Field.module.scss';

const Field = ({ children, title }) => (
  <div className={styles.container}>
    <div className={styles.title}>
      {title}
    </div>
    {children}
  </div>
);

export default Field;
