// Copyright © 2023 Esri. All rights reserved.

import React from 'react';
import { Icon, faTrashAlt } from '../Components/Chrome/Elements/Icon';
import styles from './DeletedBanner.module.scss';

export default function DeletedBanner({ data, onRestore }) {
    const dateFormatter = new Intl.DateTimeFormat(undefined, {
        dateStyle: 'medium',
    });

    const dateTimeFormatter = new Intl.DateTimeFormat(undefined, {
        dateStyle: 'medium',
        timeStyle: 'medium',
    });

    return (
        <p className={styles.deletedBanner}>
        <Icon icon={faTrashAlt} />
        Deleted on {dateTimeFormatter.format(new Date(data.deletedAt))} and will be purged after {dateFormatter.format(new Date(data.retainUntil))}
        {' '}<button onClick={onRestore}>Restore</button>
        </p>
    )
}
