// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CloudApi from '../../CloudApi';
import ProjectPermissionSelect from './ProjectPermissionSelect';
import Table from '../Chrome/Elements/Table';
import RemoveUser from '../Users/RemoveUser';
import UserDisplay from '../Users/UserDisplay';
import sortUsers from '../../Utils/sortUsers';

const shrinkCellStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '20vw',
};

class ProjectUsers extends Component {
  static propTypes = {
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
      acl: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      users: {},
      acl: props.project.acl,
    };
  }

  componentDidMount() {
    this.userRequests = this.props.project.acl.map(this.getUserInfo);
  }

  componentDidUpdate(prevProps) {
    if (this.props.project.acl !== prevProps.project.acl) {
      this.userRequests = this.props.project.acl.map(this.getUserInfo);
    }
    if (prevProps.project.acl !== this.props.project.acl) {
      this.setState({ acl: this.props.project.acl });
    }
  }

  componentWillUnmount() {
    this.userRequests.forEach((request) => request.abort());
  }

  getUserInfo = ({ id }) => {
    const request = CloudApi.get(`users/${id}`);

    request.then(this.updateUserInfo);

    return request;
  };

  updateUserInfo = (response) => {
    this.setState((prevState) => ({
      ...prevState,
      users: { ...prevState.users, [response.body.id]: response.body },
    }));
  };

  render() {
    // Sort user list alphabetically: last name -> first name -> email
    const sortedUsers = sortUsers(this.state.acl, this.state.users);

    return (
      <Table>
        <thead>
          <tr>
            <th>Name/Email</th>
            <th>User ID</th>
            <th>Permission</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map(({ id }) => {
            const user = this.state.users[id];

            return (
              <tr key={id}>
                <td style={shrinkCellStyle}>
                  {user ? <UserDisplay user={user} /> : null}
                </td>
                <td style={shrinkCellStyle}>
                  <pre>{id}</pre>
                </td>
                <td>
                  <ProjectPermissionSelect
                    userId={id}
                    id={this.props.project.id}
                    acl={this.state.acl}
                    onChange={(acl) => this.setState({ acl })}
                  />
                </td>
                <td>
                  <RemoveUser
                    userId={id}
                    id={this.props.project.id}
                    type="projects"
                    acl={this.state.acl}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}
export default ProjectUsers;
