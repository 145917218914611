// Copyright © 2022 Esri. All rights reserved.

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './OmniSearch.module.scss';
import useSearch from './useSearch';

export default function OmniSearch({
  children,
  activeOnly,
  type,
  className,
  ...props
}) {
  const [focused, setFocused] = useState(false);
  const [keyword, setKeyword] = useState('');

  const handleChange = useCallback((event) => {
    setKeyword(event.target.value.trim());
  }, []);

  const params = useMemo(
    () => ({
      limit: 50,
      activeOnly,
      keyword,
      type,
    }),
    [activeOnly, keyword, type],
  );

  const results = useSearch(params);

  const container = useRef();

  // Check if the search input is focused on the initial render,
  // and change the local state of `focused` accordingly.
  useEffect(() => {
    if (!container.current) {
      return;
    }

    setFocused(container.current.contains(document.activeElement));
  }, []);

  useEffect(() => {
    function handleClick(event) {
      setFocused(container.current.contains(event.target));
    }

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [handleChange]);

  return (
    <span className={className} ref={container}>
      <input
        {...props}
        type="search"
        onInput={handleChange}
        className={styles.input}
      />

      {children(focused && keyword ? results : {})}
    </span>
  );
}
