// Copyright © 2022 Esri. All rights reserved.

import { map } from 'lodash';
import React from 'react';
import Table from '../Chrome/Elements/Table';

import DataProcessRow from './DataProcessRow'
import { dataprocessStatuses } from '../../CloudApi'

/**
 * subset of:
 * https://github.com/ArcGIS/sitescan-api/blob/dd20df4f0e7d77b511981ac65b2d4921eb7e64c2/src/main/scala/com/arcgis/sitescan/api/models/DataProcess.scala#L124
 * excluding archaic process types
 */
const dataProcessTypes = [
  'recap',
  'pix4d',
  'panorama',
  'gcpdetect',
  'pavementcracks',
  'mineMeasurement',
  'SURE',
  'AT'
]

function Filter({options, current, onChange}){
  return (
    <select value={current || ""} onChange={e => onChange(e.target.value ? e.target.value : null)}>
      <option value="">All</option>
      {
        options.map(opt =>
          <option key={opt} value={opt}>{opt}</option>
        )
      }
    </select>
  )
}

export default function DataProcesses({ statusMessage, processes, filter, onSetFilter }) {
  return (
    <Table>
      <colgroup>
        <col />
        <col style={{"width": "21em"}} />
        <col />
        <col style={{"width": "8em"}} />
        <col style={{"width": "20em"}} />
        <col style={{"minWidth": "5em"}} />
      </colgroup>
      <thead>
        <tr>
          <th>
            <p>Type</p>
            {
              filter && <p>
                <Filter
                  options={dataProcessTypes}
                  current={filter['processType']}
                  onChange={v => onSetFilter('processType', v)} />
              </p>
            }
          </th>
          <th>
            DataProcess ID
            <br />
            Mission ID
            <br />
            Name
          </th>
          <th>Creator</th>
          <th>Settings</th>
          <th>
            Start Time
            <br />
            Last Updated
          </th>
          <th>
            <p>Status</p>
            {
              filter && <p>
                <Filter
                  options={dataprocessStatuses}
                  current={filter['status']}
                  onChange={v => onSetFilter('status', v)} />
              </p>
            }
          </th>
        </tr>
      </thead>
      <tbody>
        {
          statusMessage && <tr>
            <td colSpan="6">
              <strong>{statusMessage}</strong>
            </td>
          </tr>
        }
        {
          processes !== null && processes.length === 0 && <tr>
            <td colSpan="6">0 rows found</td>
          </tr>
        }
        {
          processes && map(processes, (process) => <DataProcessRow key={process.id} process={process}/>)
        }
      </tbody>
    </Table>
  )
}