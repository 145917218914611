// Copyright © 2022 Esri. All rights reserved.

import React, { useCallback, useEffect, useState } from 'react';
import CloudApi from '../../CloudApi';
import formatError from '../../Utils/formatError';
import styles from './OrganizationUpdateForm.module.scss';

export default function OrganizationUpdateForm({ id, ...props }) {
  const { handleSubmit, data, isLoading } = useOrganizationFormSubmit(id);
  const [status, setStatus] = useState();

  useEffect(() => {
    if (!data) return;

    setStatus('saved');
    const timeoutId = setTimeout(() => setStatus(), 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [data]);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div {...props} />
      <button type="submit">Save</button>
      {isLoading ? 'saving...' : null}
      {status ? status : null}
    </form>
  );
}

function useOrganizationFormSubmit(id) {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    (event) => {
      const formData = new FormData(event.target);

      setIsLoading(true);

      CloudApi.put(`organizations/${id}`, Object.fromEntries(formData))
        .then(
          (response) => {
            setData(response.body);
          },
          (error) => {
            alert(
              formatError(error) ||
                'There was an error updating the organization.',
            );
          },
        )
        .finally(() => {
          setIsLoading(false);
        });

      event.preventDefault();
    },
    [id],
  );

  return { handleSubmit, isLoading, data };
}
