// Copyright © 2020 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuid } from 'uuid';
import CloudApi from '../../CloudApi';
import { Icon, faEdit } from '../Chrome/Elements/Icon';
import Input from '../Chrome/Elements/Input';
import styles from './OrganizationLicenseSettings.module.scss';

class OrganizationOperatorLicenses extends React.Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    operatorLicenses: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    operatorLicenses: 0,
  };

  state = {
    isEditing: false,
    operatorLicenses: this.props.operatorLicenses,
  };

  startEditing = () => this.setState({ isEditing: true });
  stopEditing = () => {
    // eslint-disable-next-line no-restricted-globals
    const operatorLicenses =
      isNaN(this.state.operatorLicenses) || this.state.operatorLicenses < 0
        ? this.props.operatorLicenses
        : this.state.operatorLicenses;
    this.setState({ isEditing: false, operatorLicenses });
  };

  updateOperatorLicenseCount = (event) => {
    const value = parseInt(event.target.value, 10);
    this.setState({ operatorLicenses: value });
  };

  updateOrganization = () => {
    // validate=false flag allows userAdmins (anyone with ss admin access) to update license counts
    const url = `organizations/${this.props.organizationId}?validate=false`;
    this.updateRequest = CloudApi.put(url, {
      operatorLicenseCount: this.state.operatorLicenses,
    });

    this.updateRequest.then(() => {
      this.props.onChange(uuid());
      this.stopEditing();
    });
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.updateOrganization();
    }

    if (event.keyCode === 27) {
      this.stopEditing();
    }
  };

  render() {
    return (
      <div className={`${styles.operator} ${styles.block} ${styles.editable}`}>
        <span>Operator Licenses: </span>
        {this.state.isEditing ? (
          <Input
            className={styles.input}
            type="number"
            value={this.state.operatorLicenses}
            onChange={this.updateOperatorLicenseCount}
            onBlur={this.updateOrganization}
            onKeyDown={this.handleKeyDown}
            step={1}
            min={0}
            autoFocus
          />
        ) : (
          <span className={styles.licenseCount} onClick={this.startEditing}>
            {this.state.operatorLicenses}
            <Icon icon={faEdit} className={styles.icon} />
          </span>
        )}
      </div>
    );
  }
}

export default OrganizationOperatorLicenses;
