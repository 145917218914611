// Copyright © 2022 Esri. All rights reserved.

import { get } from 'lodash';
import React from 'react';
import CloudApi from '../../CloudApi';

export default function OrganizationPermissionSelect({
  organizationId,
  userId,
  permission,
  onChange,
}) {
  const handleChange = (event) => {
    const data = { permission: event.target.value };
    const endpoint = `organizations/${organizationId}/users/${userId}`;
    const downgradeEndpoint = `${endpoint}?downgradeProjectPermissions=true`;
    const confirmDowngrade =
      'This change requires downgrading user project permissions.';

    CloudApi.put(endpoint, data)
      .then(
        (response) => response,
        (error) => {
          switch (get(error, 'response.body.error')) {
            case 'project_permission_validation': {
              if (window.confirm(confirmDowngrade)) {
                return CloudApi.put(downgradeEndpoint, data);
              }

              return Promise.reject(); // reject to skip acl update, but no actionable error.
            }

            case 'license_limit':
              return Promise.reject('Cannot exceed license limit.');

            default:
              return Promise.reject(
                'There was an error updating user permission.',
              );
          }
        },
      )
      .then(
        (response) => {
          onChange(response.body.acl);
        },
        (error) => {
          if (error) {
            window.alert(error);
          }
        },
      );
  };

  return (
    // TODO: When $ is removed from API responses, we can remove replace('$', '')
    <select value={permission.replace('$', '')} onChange={handleChange}>
      <option value="Admin">Admin</option>
      <option value="Write">Write</option>
      <option value="Read">Read</option>
    </select>
  );
}
