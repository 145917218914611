// Copyright © 2019 3D Robotics. All rights reserved.

import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import UAParser from 'ua-parser-js';
import Button from '../Chrome/Elements/Button';

class UserSessionItem extends Component {
  static propTypes = {
    onRevoke: PropTypes.func.isRequired,
    session: PropTypes.shape({
      id: PropTypes.string.isRequired,
      lastUsed: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      clientIp: PropTypes.string.isRequired,
      userAgent: PropTypes.string.isRequired,
      scope: PropTypes.string.isRequired,
    }).isRequired,
  };

  state = {
    error: undefined,
  };

  handleRevoke = () => {
    this.props
      .onRevoke(this.props.session.id)
      .then(() => {}, error => this.setState({ error }));
  };

  render() {
    const {
      clientIp,
      lastUsed,
      created,
      userAgent,
      scope,
    } = this.props.session;

    const ua = UAParser(userAgent);
    let appVersion;

    // Example sitescan-field ua: Site%20Scan/140 CFNetwork/808.2.16 Darwin/16.3.0
    // The parser doesn't provide OS version data, but we can extract the app version from the string.
    if (scope === 'sitescan-field') {
      const uaString = get(ua, 'ua');
      // Remove %20 instances from string and replace with a space
      appVersion = uaString && uaString.replace(/%20/g, ' ');
    }

    // Sessions within 5 minutes are considered "now"
    const isJustNow = moment(lastUsed)
      .add(5, 'minutes')
      .isAfter();

    return (
      <div
        style={{
          borderBottom: '1px solid #eee',
          margin: '2em',
          paddingBottom: '2em',
        }}
      >
        <Button
          size="medium"
          modifiers="danger"
          onClick={this.handleRevoke}
          style={{ float: 'right' }}
        >
          Revoke
        </Button>

        <div>
          <strong>{clientIp}</strong>
        </div>

        <div>
          Last accessed on {moment(lastUsed).format('LLL')}
          {isJustNow && (
            <strong style={{ color: 'green', marginLeft: '0.2em' }}>
              (current session)
            </strong>
          )}
        </div>

        <div style={{ marginTop: '1em' }}>
          <strong>{ua.browser.name}</strong> {ua.browser.version} on{' '}
          <strong>{ua.os.name}</strong> {appVersion || ua.os.version}
        </div>

        <div style={{ marginTop: '1em' }}>
          <div>
            <strong>Signed in:</strong>
          </div>
          <div>{moment(created).format('LLL')}</div>
        </div>

        <div style={{ marginTop: '1em' }}>
          <strong>{scope}</strong>
        </div>

        {this.state.error && (
          <div style={{ marginTop: '1m' }}>{this.state.error}</div>
        )}
      </div>
    );
  }
}

export default UserSessionItem;
