// Copyright © 2021 Esri. All rights reserved.

import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import CloudApi from '../../CloudApi';
import editIcon from '../../Images/edit.svg';
import OmniSearch from '../Search/OmniSearch';
import Popup from '../Chrome/Elements/Popup';
import { Link } from 'react-router-dom';
import styles from './ChangeOrganization.module.scss';

const ChangeOrganization = ({ projectId, organizationId }) => {
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState();
  const [organization, setOrganization] = useState();

  useEffect(() => {
    CloudApi.get(`organizations/${organizationId}`).then((res) => {
      setOrganization(res.body);
    });
  }, [organizationId]);

  const handleSuccess = (id) => {
    setIsEditing(false);
    setSelectedOrganizationId(id);
    setIsConfirmationOpen(false);
    window.location.reload();
  };

  const handleError = () => {
    const errorMessage =
      'Error: Make sure you have admin access on the updated organization.';

    setIsEditing(false);
    setError(errorMessage);
    setIsConfirmationOpen(false);
  };

  const handleSuggestionSelect = () => {
    setError();

    const saveRequest = CloudApi.put(`projects/${projectId}/organization`, {
      organizationId: selectedOrganizationId,
    });

    saveRequest.then(() => {
      handleSuccess(selectedOrganizationId);
    }, handleError);
  };

  const confirmSelection = (result) => {
    if (!isConfirmationOpen) {
      setSelectedOrganizationId(result.organizationId);
      setIsConfirmationOpen(true);
    } else {
      handleSuggestionSelect();
    }
  };

  const toggleEditMode = useCallback(() => {
    setIsEditing(!isEditing);

    if (!isEditing) {
      setError(null);
    }
  }, [isEditing]);

  const closeConfirmation = () => setIsConfirmationOpen(false);

  return (
    <section>
      <div className={styles.container}>
        {isEditing ? (
          <OmniSearch placeholder="Organization name" type="organization">
            {({ data }) =>
              (!isEmpty(data) || isConfirmationOpen) && (
                <div className={styles.results}>
                  {map(data, (result) => {
                    const name =
                      (result.name && result.name.trim()) || 'No Name';

                    return (
                      <button
                        onClick={() => confirmSelection(result)}
                        key={result.organizationId}
                      >
                        {name}
                      </button>
                    );
                  })}
                </div>
              )
            }
          </OmniSearch>
        ) : (
          <h2>
            <Link to={`/organizations/${organizationId}`}>
              {!isEmpty(organization) ? organization.name : 'Loading organization...'}
            </Link>
          </h2>
        )}
        <button onClick={toggleEditMode} className={styles.edit}>
          <img src={editIcon} alt="pencil" />
        </button>
      </div>

      <Popup
        isOpen={isConfirmationOpen}
        header="Member access may change"
        message={`Members who belong to the updated organization will receive project-level permissions equal to
          their organization-level permissions. For example, if a member is a project administrator but read-only
          on the new organization they will become read-only on the project. Members who do not belong to
          the updated organization will be removed from project permissions.`}
        onClose={closeConfirmation}
        onSubmit={handleSuggestionSelect}
        actionLabel="Move Project"
      />

      {error && <div className={styles.error}>{error}</div>}
    </section>
  );
};

ChangeOrganization.propTypes = {
  projectId: PropTypes.string.isRequired,
  organizationId: PropTypes.string,
};

export default ChangeOrganization;
