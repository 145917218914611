// Copyright © 2022 Esri. All rights reserved.

import React, { useCallback, useEffect, useRef, useState } from 'react';
import CloudApi from '../../CloudApi';
import DataProcesses from './DataProcesses';
import { Link, useLocation } from 'react-router-dom';

function Status({history}) {
  const location = useLocation()
  const [status, setStatus] = useState('loading')
  const [processes, setProcesses] = useState(null)
  const [nextPageToken, setNextPageToken] = useState(null)

  const request = useRef(null)
  const refreshTimeout = useRef(null)

  const doRequest = useCallback(() => {
    if (request.current) {
      request.current.abort()
    }
    clearTimeout(refreshTimeout.current)

    request.current = CloudApi.get(`dataprocess/list${location.search}`);
    request.current.then(response => {
      setStatus('loaded')
      setProcesses(response.body['items'])
      setNextPageToken(response.body['next'])
      refreshTimeout.current = setTimeout(() => doRequest(), 30 * 1000)
    }, (e) => {
      setStatus(e)
    });
  }, [location]);

  useEffect(() => {
    setStatus('loading')
    setProcesses(null)
    setNextPageToken(null)
    doRequest()
    return () => {
      if (request.current) {
        request.current.abort()
      }
      clearTimeout(refreshTimeout.current)
    }
  }, [doRequest])

  function handleNewFilter(key, newValue) {
    const newSearch = new URLSearchParams(location.search)
    if (newValue) {
      newSearch.set(key, newValue);
    } else {
      newSearch.delete(key)
    }
    history.push({search: newSearch.toString()})
  }

  var statusMessage = null;
  if (status === 'loading') {
    statusMessage = 'Loading';
  } else if (status instanceof Error) {
    statusMessage = `There was an error retrieving processing data. ${status.toString()}`;
  }

  let firstPageSearch = new URLSearchParams(location.search)
  if (firstPageSearch.has('page')) {
    firstPageSearch.delete('page');
  } else {
    firstPageSearch = null;
  }

  let nextPageSearch = null
  if (nextPageToken) {
    nextPageSearch = new URLSearchParams(location.search)
    nextPageSearch.set('page', nextPageToken)
  }

  return (
    <div style={{ padding: '2em', flex: 1, overflow: 'auto' }}>
      <h1>Processing Status</h1>
      {
        firstPageSearch && <p><Link to={{search:firstPageSearch.toString()}}>Jump to first page</Link></p>
      }
      <DataProcesses
        statusMessage={statusMessage}
        processes={processes}
        filter={Object.fromEntries(new URLSearchParams(location.search))}
        onSetFilter={(key, newValue) => handleNewFilter(key, newValue)} />
      {
        nextPageSearch && <p><Link to={{search:nextPageSearch.toString()}}>Next page</Link></p>
      }
    </div>
  );
}

export default Status;
