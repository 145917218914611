// Copyright © 2021 Esri. All rights reserved.

import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import styles from './HeaderSearch.module.scss';
import HeaderSearchResult from './HeaderSearchResult';
import OmniSearch from './OmniSearch';

const HeaderSearch = () => {
  const { pathname } = useLocation();
  const [includeInactive, setIncludeInactive] = useState(false);

  return (
    <div className={styles.container}>
      <OmniSearch
        placeholder="Search"
        activeOnly={!includeInactive}
        className={styles.search}
        key={pathname} // reset search when navigating (clicked on result)
      >
        {({ data }) =>
          !isEmpty(data) && (
            <div className={styles.results}>
              {data.map((result) => {
                const id = result.userId || result.organizationId;
                return <HeaderSearchResult key={id} result={result} />;
              })}
            </div>
          )
        }
      </OmniSearch>

      <label>
        <input
          type="checkbox"
          checked={includeInactive}
          onChange={() => setIncludeInactive(!includeInactive)}
        />{' '}
        Include Inactive
      </label>
    </div>
  );
};

export default HeaderSearch;
