// Copyright © 2019 3D Robotics. All rights reserved.

import { some, uniqBy } from 'lodash';
import isEmailValid from './isEmailValid';

export const filterUnusedEntries = (users, showHeaderRows) => {
  // Filter out empty manual input rows and header rows if applicable
  const filteredUsers = users.filter((user) => {
    if (user.isHeaderRow && !showHeaderRows) {
      return false;
    }

    return !(user.name === '' && user.email === '');
  });

  // Remove entries with duplicate emails
  return uniqBy(filteredUsers, 'email');
};

// TODO: Make a bit more robust
const isUserValid = (user) => {
  if (!isEmailValid(user.email)) {
    return false;
  }

  if (user.name === '' || !user.name) {
    return false;
  }

  return true;
};

export const validateUsers = (allUsers, showHeaderRows) => {
  const users = filterUnusedEntries(allUsers, showHeaderRows);
  const validUsers = users.filter(isUserValid);

  const error = validUsers.length < users.length ? 'All entries must contain a valid email and name. Make sure all email entries contain no white space.' : null;
  return error;
};

export const validateUploadFile = (users) => {
  // TODO: make more dynamic as more columns are added
  const SCHEMA_COLUMNS = 2;
  const invalidSchema = some(users, (user => user.length !== SCHEMA_COLUMNS));
  return invalidSchema ? 'Error uploading file: Invalid schema. File columns should match the table above' : null;
};

export const formatUserUploads = users => (
  // TODO: Potentially refactor as new columns are added to be more flexible
  users.map((user, idx) => ({
    email: user[0] || '',
    name: user[1] || '',
    isHeaderRow: idx === 0,
  }))
);
