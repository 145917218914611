// Copyright © 2019 3D Robotics. All rights reserved.

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { deleteToken } from './Lock';
import cloudApi from '../../CloudApi';

const onLogout = () => {
  cloudApi.del("users/me/sessions/current").then();
  deleteToken();
}

const Logout = () => (
  <Link to="/auth" onClick={onLogout}>Logout</Link>
);

export default withRouter(Logout);
