// Copyright © 2022 Esri. All rights reserved.

import { isEmpty, map } from 'lodash';
import React, { useState } from 'react';
import OmniSearch from './OmniSearch';
import SearchResult from './SearchResult';
import styles from './Search.module.scss';

const Search = () => {
  const [includeInactive, setIncludeInactive] = useState(false);

  return (
    <div>
      <label className={styles.checkbox}>
        <input
          type="checkbox"
          checked={includeInactive}
          onChange={() => setIncludeInactive(!includeInactive)}
        />{' '}
        Include Inactive
      </label>

      <OmniSearch
        placeholder="Type in an Organization or Email Address"
        activeOnly={!includeInactive}
        autoFocus
        className={styles.container}
      >
        {({ isLoading, error, data }) => {
          if (isLoading) {
            return <p>...Loading</p>;
          }

          if (error) {
            return <p className={styles.error}>{error}</p>;
          }

          if (data === undefined) {
            return null; // have not started searching...
          }

          if (isEmpty(data)) {
            return <p>No matching results for your query</p>;
          }

          return (
            <div className={styles.results}>
              <table>
                <tbody>
                  {map(data, (result) => {
                    const id = result.userId || result.organizationId;
                    return <SearchResult key={id} result={result} />;
                  })}
                </tbody>
              </table>
            </div>
          );
        }}
      </OmniSearch>
    </div>
  );
};

export default Search;
