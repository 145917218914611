// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import Papa from 'papaparse';
import Dropzone from 'react-dropzone';
import { formatUserUploads, validateUploadFile } from '../../Utils/userUpload';
import styles from './NewUserOptions.module.scss';

// This component should contain logic to add more rows and to upload csv files.
class NewUserOptions extends React.Component {
  static propTypes = {
    onUserAdd: PropTypes.func.isRequired,
    onUserUpload: PropTypes.func.isRequired,
    onUploadError: PropTypes.func.isRequired,
  };

  onParseComplete = (results) => {
    // Validate user uploads. Util will return null if no errors
    const uploadError = validateUploadFile(results.data);
    this.props.onUploadError(uploadError);
    if (uploadError) {
      return null;
    }

    const formattedUsers = formatUserUploads(results.data);
    return this.props.onUserUpload(formattedUsers);
  };

  onParseError = () => {
    this.props.onUploadError(
      'An unknown error occurred while uploading this file.',
    );
  };

  onDrop = (files) => {
    files.forEach((file) => {
      const config = {
        complete: this.onParseComplete,
        error: this.onParseError,
        skipEmptyLines: true,
        header: false,
      };
      Papa.parse(file, config);
    });
  };

  setRefNode = (node) => {
    this.dropzone = node && node.dropzone;
  };

  openFileDialog = () => {
    if (this.dropzone) {
      this.dropzone.open();
    }
  };

  render() {
    return (
      <div>
        <div className={styles.uploadOptions}>
          <button type="button" onClick={this.props.onUserAdd}>
            + Add more users
          </button>
          <span> or </span>
          <button type="button" onClick={this.openFileDialog}>
            Upload a .csv
          </button>
        </div>

        <div className={styles.hidden}>
          <Dropzone
            disablePreview
            onDrop={this.onDrop}
            accept=".csv, .txt"
            multiple={false}
            ref={(node) => (this.dropzone = node)} // eslint-disable-line
          />
        </div>

        <div className={styles.uploadInstructions}>
          *Column ordering in uploaded .csv files must conform to the schema
          laid out above.
        </div>
      </div>
    );
  }
}

export default NewUserOptions;
