// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AuthRoute from './Components/Route/AuthRoute';
import NoMatchRoute from './Components/Route/NoMatchRoute';
import OrganizationsRoute from './Components/Route/OrganizationsRoute';
import ProjectsRoute from './Components/Route/ProjectsRoute';
import UsersRoute from './Components/Route/UsersRoute';
import Home from './Components/Home/Home';
import StatusRoute from './Components/Route/StatusRoute';
import FirmwareRoute from './Components/Route/FirmwareRoute';
import ReportsRoute from './Components/Route/ReportsRoute';
import AuthenticatedChrome from './Components/Chrome/AuthenticatedChrome';
import PrivateRoute from './Components/Route/PrivateRoute';
import MissionsRoute from './Components/Route/MissionsRoute';
import DataProcessRoute from './Components/Route/DataProcessRoute';
import DataProductRoute from './Components/Route/DataProductRoute';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/auth" component={AuthRoute} />
      <PrivateRoute>
        <AuthenticatedChrome>
          <Switch>
            <Route path="/users" component={UsersRoute} />
            <Route path="/missions" component={MissionsRoute} />
            <Route path="/dataprocess" component={DataProcessRoute} />
            <Route path="/dataproducts" component={DataProductRoute} />
            <Route path="/projects" component={ProjectsRoute} />
            <Route path="/organizations" component={OrganizationsRoute} />
            <Route path="/status" component={StatusRoute} />
            <Route path="/firmware" component={FirmwareRoute} />
            <Route path="/reports" component={ReportsRoute} />
            <Route exact path="/" component={Home} />
            <Route component={NoMatchRoute} />
          </Switch>
        </AuthenticatedChrome>
      </PrivateRoute>
    </Switch>
  </BrowserRouter>
);

export default App;
