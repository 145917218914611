// Copyright © 2019 3D Robotics. All rights reserved.

import React from "react";
import Button from "./Button";
import WithRouter from "../../Route/WithRouter";

const BackButton = props => (
  <WithRouter>
    {({ history }) => <Button {...props} onClick={history.goBack} />}
  </WithRouter>
);

export default BackButton;
