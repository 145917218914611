// Copyright © 2023 Esri. All rights reserved.

import { get, map } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import formatError from '../../Utils/formatError';
import useCloudApi from '../../Utils/useCloudApi';
import Button from '../Chrome/Elements/Button';
import Table from '../Chrome/Elements/Table';
import styles from './Reports.module.scss';

const formatTableHeader = (header) => {
  if (!header) {
    return '';
  }

  return header.replaceAll('_', ' ');
};

const formatCurrentDate = () => {
  const currentDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(new Date());

  if (!currentDate) {
    return undefined;
  }

  return currentDate.replace(/(,?\s)/g, '_'); // Aug 30, 2021 => Aug_30_2021
};

const formatName = (name) => name.replaceAll(' ', '_');

const getFilename = (name) => {
  const date = formatCurrentDate();
  const formattedName = formatName(name);

  return date ? `${formattedName}_${date}.csv` : `${formattedName}.csv`;
};

function saveBlob(blob, name) {
  const a = document.createElement('a');

  a.download = name;
  a.rel = 'noopener';
  a.href = URL.createObjectURL(blob);

  // revoke URL after 40s
  setTimeout(function () {
    URL.revokeObjectURL(a.href);
  }, 4e4);

  setTimeout(function () {
    a.click();
  }, 0);
}

function createCSV(headers, columns) {
  const formattedHeaders = map(headers, ({ key }) => formatTableHeader(key));

  const formattedRows = map(columns, (result) =>
    map(headers, ({ key }) => {
      if (typeof result[key] === 'string') {
        // Escape cells containing double quotation marks by doubling quotation marks.
        result[key] = result[key].replace(/"/g, '""');

        // Wrap cells in double quotation marks if they contain commas or double quotation marks.
        if (/("|,)/.test(result[key])) {
          return (result[key] = '"' + result[key] + '"');
        }
      }

      return result[key];
    }),
  );

  const content = [formattedHeaders, ...formattedRows].join('\n');

  return new Blob([content], {
    type: 'text/csv;charset=utf-8;',
  });
}

const Report = ({ id }) => {
  const { isLoading, data, error } = useCloudApi(`adminReports/${id}`);

  if (isLoading) {
    return <p className={styles.loading}>Loading...</p>;
  }

  if (error) {
    return (
      <p className={styles.error}>
        An error has occurred while fetching report. {formatError(error)}
      </p>
    );
  }

  if (!data) {
    return null;
  }

  const columns = get(data, 'columns');
  const results = get(data, 'results');
  const name = get(data, 'name');

  const downloadCSV = (columns, results, filename) => {
    const blob = createCSV(columns, results);
    saveBlob(blob, filename);
  };

  return (
    <article className={styles.resultsContainer}>
      <header>
        <h1>{name}</h1>

        <Button
          size="medium"
          onClick={() => downloadCSV(columns, results, getFilename(name))}
        >
          Download .csv
        </Button>
      </header>

      <Table>
        <thead>
          <tr>
            {map(columns, ({ key }) => (
              <th key={key}>{formatTableHeader(key)}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {map(results, (result, index) => (
            <tr key={index}>
              {map(columns, ({ key }) => (
                <td key={key}>
                  <FormattedField field={key} value={result[key]} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </article>
  );
};

export default Report;

function FormattedField({ field, value }) {
  switch (field) {
    case 'organization_id':
      return <Link to={`/organizations/${value}`}>{value}</Link>;

    default:
      return value !== null ? value.toString() : '';
  }
}
