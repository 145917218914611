// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuid } from 'uuid';
import CloudApi from '../../CloudApi';
import Input from '../Chrome/Elements/Input';
import { Icon, faEdit } from '../Chrome/Elements/Icon';
import styles from './OrganizationLicenseSettings.module.scss';

class OrganizationLicenses extends React.Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    usedLicenses: PropTypes.number,
    paidLicenses: PropTypes.number,
    bonusLicenses: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    usedLicenses: 0,
    paidLicenses: 0,
    bonusLicenses: 0,
  };

  state = {
    isEditingPaid: false,
    isEditingBonus: false,
    paidLicenses: this.props.paidLicenses,
    bonusLicenses: this.props.bonusLicenses,
  };

  startEditingPaid = () => this.setState({ isEditingPaid: true });

  startEditingBonus = () => this.setState({ isEditingBonus: true });

  stopEditing = () => {
    // eslint-disable-next-line no-restricted-globals
    const paidLicenses =
      isNaN(this.state.paidLicenses) || this.state.paidLicenses < 0
        ? this.props.paidLicenses
        : this.state.paidLicenses;
    // eslint-disable-next-line no-restricted-globals
    const bonusLicenses =
      isNaN(this.state.bonusLicenses) || this.state.bonusLicenses < 0
        ? this.props.bonusLicenses
        : this.state.bonusLicenses;

    this.setState({
      isEditingPaid: false,
      isEditingBonus: false,
      paidLicenses,
      bonusLicenses,
    });
  };

  updatePaidLicenses = (event) => {
    const value = parseInt(event.target.value, 10);
    this.setState({ paidLicenses: value });
  };

  updateBonusLicenses = (event) => {
    const value = parseInt(event.target.value, 10);
    this.setState({ bonusLicenses: value });
  };

  updateOrganization = () => {
    // validate=false flag allows userAdmins (anyone with ss admin access) to update license counts
    const url = `organizations/${this.props.organizationId}?validate=false`;
    this.updateRequest = CloudApi.put(url, {
      licenseCount: this.state.paidLicenses + this.state.bonusLicenses,
      paidLicenseCount: this.state.paidLicenses,
    });

    this.updateRequest.then(() => {
      this.props.onChange(uuid());
      this.stopEditing();
    });
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.updateOrganization();
    }

    if (event.keyCode === 27) {
      this.stopEditing();
    }
  };

  render() {
    return (
      <div>
        <div className={`${styles.paid} ${styles.block} ${styles.editable}`}>
          <span>Paid Licenses: </span>
          {this.state.isEditingPaid ? (
            <Input
              className={styles.input}
              type="number"
              value={this.state.paidLicenses}
              onChange={this.updatePaidLicenses}
              onBlur={this.updateOrganization}
              onKeyDown={this.handleKeyDown}
              step={1}
              min={0}
              autoFocus
            />
          ) : (
            <span
              className={styles.licenseCount}
              onClick={this.startEditingPaid}
            >
              {this.state.paidLicenses}
              <Icon icon={faEdit} className={styles.icon} />
            </span>
          )}
        </div>

        <div className={`${styles.bonus} ${styles.block} ${styles.editable}`}>
          <span>Bonus Licenses: </span>
          {this.state.isEditingBonus ? (
            <Input
              className={styles.input}
              type="number"
              value={this.state.bonusLicenses}
              onChange={this.updateBonusLicenses}
              onBlur={this.updateOrganization}
              onKeyDown={this.handleKeyDown}
              step={1}
              min={0}
              autoFocus
            />
          ) : (
            <span
              className={styles.licenseCount}
              onClick={this.startEditingBonus}
            >
              {this.state.bonusLicenses}
              <Icon icon="edit" className={styles.icon} />
            </span>
          )}
        </div>

        <div className={styles.block}>
          <span>Licenses Used: </span>
          <span className={styles.licenseCount}>
            {this.props.usedLicenses} /{' '}
            {this.state.paidLicenses + this.state.bonusLicenses}
          </span>
        </div>
      </div>
    );
  }
}

export default OrganizationLicenses;
