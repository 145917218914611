// Copyright © 2019 3D Robotics. All rights reserved.

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CloudApi from '../../CloudApi';
import config from '../../config';
import Button from '../Chrome/Elements/Button';

class UserImpersonateButton extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
  };

  componentWillUnmount() {
    if (this.request) {
      this.request.abort();
    }
  }

  impersonate = () => {
    const impersonatorWindow = window.open();

    this.request = CloudApi.post(`users/${this.props.userId}/sessions/`);

    this.request.then((response) => {
      const encodedToken = encodeURIComponent(response.body.token);
      if (impersonatorWindow) {
        impersonatorWindow.location = `${config.managerUrl}/auth#token=${encodedToken}`;
      }
    });
  };

  render() {
    return (
      <Button size="medium" onClick={this.impersonate}>
        Impersonate User
      </Button>
    );
  }
}

export default UserImpersonateButton;
