// Copyright © 2024 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CloudApi from '../../CloudApi';
import BodyChrome from '../Chrome/BodyChrome';
import TabbedContent from '../Chrome/TabbedContent';
import ButtonLink from '../Chrome/Elements/ButtonLink';
import OrganizationAddUser from './OrganizationAddUser';
import OrganizationProjectsTable from './OrganizationProjectsTable';
import OrganizationUserTable from './OrganizationUserTable';
import OrganizationName from './OrganizationName';
import LicenseTypeDropdown from './LicenseTypeDropdown';
import OrganizationArcGIS from './OrganizationArcGIS';
import OrganizationArcGISOrganization from './OrganizationArcGISOrganization';
import OrganizationOperatorLicenses from './OrganizationOperatorLicenses';
import OrganizationOperatorLicenseModel from './OrganizationOperatorLicenseModel';
import OrganizationLicencedFeatures from './OrganizationLicencedFeatures';
import OrganizationLicenses from './OrganizationLicenses';
import OrganizationSetIcon from './OrganizationSetIcon';
import OrganizationShareSetting from './OrganizationShareSetting';
import OrganizationValidation from './OrganizationValidation';
import DeactivationDateCalendar from './DeactivationDateCalendar';
import AccountManagerDropdown from './AccountManagerDropdown';
import OperatorLicenseManagement from './OperatorLicenseManagement';
import Pix4dProcessingAllowance from './Pix4dProcessingAllowance';
import OrganizationPhotoUsage from './OrganizationPhotoUsage';
import styles from './Organization.module.scss';
import OrganizationEsriCustomerNumber from './OrganizationEsriCustomerNumber';
import OrganizationInternalNotes from './OrganizationInternalNotes';

class Organization extends Component {
  static propTypes = {
    orgId: PropTypes.string.isRequired,
  };

  state = {
    organization: undefined,
    hash: '',
  };

  componentDidMount() {
    this.loadOrganization(this.props.orgId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.orgId !== prevProps.orgId) {
      this.loadOrganization(this.props.orgId);
    }
  }

  componentWillUnmount() {
    this.orgRequest.abort();
  }

  updateACL = (acl) =>
    this.setState({ organization: { ...this.state.organization, acl } });

  loadOrganization(organizationId) {
    // Reset state
    this.setState({ organization: undefined });

    // Abort in-progress requests
    if (this.orgRequest) {
      this.orgRequest.abort();
    }
    if (this.projectsRequest) {
      this.projectsRequest.abort();
    }

    this.orgRequest = CloudApi.get(`organizations/${organizationId}`);

    this.orgRequest.then((response) => {
      this.setState({ organization: response.body });
    });
  }

  render() {
    if (!this.state.organization) {
      return <div>Loading...</div>;
    }

    const orgPath = `/organizations/${this.props.orgId}`;
    const projectsPath = `/organizations/${this.props.orgId}/projects`;
    const deletedProjectsPath = `/organizations/${this.props.orgId}/projects/deleted`;
    const newProjectPath = `/projects/new?organizationId=${this.props.orgId}`;
    const newUserPath = `/users/new?organizationId=${this.props.orgId}`;

    const paidLicenses = Number.isNaN(this.state.organization.paidLicenseCount)
      ? 0
      : this.state.organization.paidLicenseCount;
    const bonusLicenses = this.state.organization.licenseCount - paidLicenses;

    return (
      <BodyChrome>
        <OrganizationValidation
          orgId={this.props.orgId}
          hash={this.state.hash}
        />

        <div>
          <div className={styles.header} style={{ marginBottom: 16 }}>
            <OrganizationName
              name={this.state.organization.name}
              organizationId={this.props.orgId}
            />

            <div className={styles.actions}>
              <OrganizationAddUser organizationId={this.props.orgId} />

              <ButtonLink size="medium" to={newUserPath}>
                Add New User
              </ButtonLink>

              <ButtonLink size="medium" to={newProjectPath}>
                Add New Project
              </ButtonLink>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <AccountManagerDropdown
                organizationId={this.props.orgId}
                accountManagers={this.state.organization.accountManagers}
              />

              <LicenseTypeDropdown
                organizationId={this.props.orgId}
                licenseType={this.state.organization.licenseType}
              />

              <OrganizationLicenses
                organizationId={this.props.orgId}
                usedLicenses={this.state.organization.usedLicenseCount}
                paidLicenses={paidLicenses}
                bonusLicenses={bonusLicenses}
                onChange={(hash) => this.setState({ hash })}
              />

              <OrganizationOperatorLicenses
                organizationId={this.props.orgId}
                operatorLicenses={this.state.organization.operatorLicenseCount}
                onChange={(hash) => this.setState({ hash })}
              />

              <OrganizationOperatorLicenseModel
                organizationId={this.props.orgId}
                legacyOperatorLicenseModel={
                  this.state.organization.legacyOperatorLicenseModel
                }
              />

              <OperatorLicenseManagement
                organizationId={this.props.orgId}
                operatorLicenseManagement={
                  this.state.organization.operatorLicenseManagement
                }
              />

              <Pix4dProcessingAllowance
                organizationId={this.props.orgId}
                allowEngineOverride={
                  this.state.organization.allowEngineOverride
                }
              />

              <DeactivationDateCalendar
                organizationId={this.props.orgId}
                date={this.state.organization.active}
              />

              <OrganizationPhotoUsage organizationId={this.props.orgId} />

              <OrganizationShareSetting
                organizationId={this.props.orgId}
                defaultChecked={this.state.organization.shareLinkEnabled}
              />

              <OrganizationLicencedFeatures
                organizationId={this.props.orgId}
                features={this.state.organization.licensedFeatures}
              />

              <OrganizationArcGIS
                {...this.state.organization.arcGIS}
                organizationId={this.props.orgId}
              />
            </div>

            <div>
              <OrganizationArcGISOrganization
                {...this.state.organization.arcgisOrganization}
              />

              <OrganizationEsriCustomerNumber {...this.state.organization} />

              <OrganizationInternalNotes {...this.state.organization} />
            </div>

            <div>
              <OrganizationSetIcon
                icon={this.state.organization.icon}
                organizationId={this.props.orgId}
              />
            </div>
          </div>
        </div>

        <TabbedContent
          tabs={[
            {
              link: { to: orgPath, exact: true, children: 'Users' },
              route: {
                path: '/organizations/:orgId',
                exact: true,
                render: () => (
                  <OrganizationUserTable
                    organization={this.state.organization}
                    updateACL={this.updateACL}
                    onChange={(hash) => this.setState({ hash })}
                  />
                ),
              },
            },
            {
              link: { to: projectsPath, exact: true, children: 'Projects' },
              route: {
                path: '/organizations/:orgId/projects',
                exact: true,
                render: () => (
                  <OrganizationProjectsTable
                    organizationId={this.props.orgId}
                    deleted={false}
                  />
                ),
              },
            },
            {
              link: {
                to: deletedProjectsPath,
                exact: true,
                children: 'Deleted Projects',
              },
              route: {
                path: '/organizations/:orgId/projects/deleted',
                exact: true,
                render: () => (
                  <OrganizationProjectsTable
                    organizationId={this.props.orgId}
                    deleted={true}
                  />
                ),
              },
            },
          ]}
        />
      </BodyChrome>
    );
  }
}

export default Organization;
