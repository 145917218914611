// Copyright © 2021 Esri. All rights reserved.

import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import CloudApi from '../../CloudApi';
import { AuthenticatedUserProvider } from '../Auth/AuthenticatedUserContext';
import { getToken } from '../Auth/Lock';

const PrivateRoute = ({ children, ...rest }) => {
  const token = getToken();

  // if no token, user not authenticated.
  const [user, setUser] = useState(token ? undefined : null);

  useEffect(() => {
    if (!token) return;

    // API will not give tokens to non-admins.
    // If we can access 'users/me' endpoint - we are authenticated.
    CloudApi.get('users/me').then((response) => setUser(response.body));
  }, [token]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        // user is undefined until fetched from API
        if (user === undefined) {
          return 'Loading...';
        }

        return user ? (
          <AuthenticatedUserProvider value={user}>
            {children}
          </AuthenticatedUserProvider>
        ) : (
          <Redirect to={{ pathname: '/auth', state: { from: location } }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
