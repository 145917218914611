// Copyright © 2022 Esri. All rights reserved.

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DataProcessPage from '../DataProcess/DataProcessPage';

export default function DataProcessRoute() {
  return (
    <Switch>
      <Route
        path="/dataprocess/:dataProcessId"
        render={({ match }) => (
          <DataProcessPage dataProcessId={match.params.dataProcessId} />
        )}
      />
    </Switch>
  );
}
