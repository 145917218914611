// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import NewUserListItem from './NewUserListItem';
import Label from '../Chrome/Elements/Label';
import styles from './NewUserList.module.scss';

export default function NewUserList({
  users,
  onToggleHeaderRows,
  onColumnChange,
  column,
  showHeaderRows,
  duplicates,
}) {
  function handleColumnChange(event) {
    onColumnChange(event.target.value);
  }

  return (
    <div>
      <div className={styles.header}>
        <div>New User List</div>
        <div className={styles.field}>
          <Label className={styles.label}>Include upload header rows</Label>
          <input
            type="checkbox"
            checked={showHeaderRows}
            onChange={onToggleHeaderRows}
          />
        </div>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Email</th>
            <th>
              <label>
                <input
                  type="radio"
                  name="column"
                  value="name"
                  onChange={handleColumnChange}
                  checked={column === 'name'}
                />
                Name
              </label>

              <label>
                <input
                  type="radio"
                  name="column"
                  value="arcgisUsername"
                  onChange={handleColumnChange}
                  checked={column === 'arcgisUsername'}
                />
                ArcGIS Username{' '}
              </label>
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, idx) => (
            <NewUserListItem
              key={user.email ? `${user.email}-${idx}` : idx}
              user={user}
              hidden={!showHeaderRows && user.isHeaderRow}
              column={column}
              duplicates={duplicates}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

NewUserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape()),
  onToggleHeaderRows: PropTypes.func.isRequired,
  showHeaderRows: PropTypes.bool.isRequired,
};
