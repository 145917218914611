// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CloudApi from '../../CloudApi';
import formatError from '../../Utils/formatError';
import Button from '../Chrome/Elements/Button';
import UserSelect from '../Users/UserSelect';

// Add user to a project
export default function ProjectAddUser({ organizationId, projectId }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  function submit() {
    const defaultPermission = 'Read';

    // use the invite endpoint so we can add user to organization at the same time as project if needed
    const request = CloudApi.post(`organizations/${organizationId}/invite`, {
      users: [
        {
          ...user,
          permission: defaultPermission,
          operator: false,
          projects: { [projectId]: { permission: defaultPermission } },
        },
      ],
    });

    // after adding user, refresh page because we have no way (currently) to invalidate the user list on the page.
    request.then(() => window.location.reload(), setError);
  }

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <UserSelect onSelect={setUser} />

        <Button disabled={!user} onClick={submit} size="medium">
          Add User
        </Button>
      </div>

      {error ? (
        <div style={{ maxWidth: '250px' }}>
          {formatError(error) || 'There was an unknown error adding this user.'}
        </div>
      ) : null}
    </div>
  );
}

ProjectAddUser.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};
