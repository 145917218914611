// Copyright © 2021 Esri. All rights reserved.

import { map, sortBy, difference, concat } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import CloudApi from '../../CloudApi';

function formatUserAsOption(user) {
  return { value: user.userId, label: user.name };
}

const AccountManagerDropdown = ({ organizationId, accountManagers }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const request = CloudApi.get(`organizations/${organizationId}`);

    request.then((response) => {
      if (!response.body) {
        return;
      }

      const options = response.body.users.map(formatUserAsOption);
      const sortedOptions = sortBy(options, ({ label }) => label.toLowerCase());

      setOptions(sortedOptions);
    });

    return () => {
      request.abort();
    };
  }, [organizationId]);

  const [managers, setManagers] = useState([]);

  useEffect(() => {
    setManagers(
      accountManagers
        .map((userId) => options.find(({ value }) => value === userId))
        .filter((user) => !!user),
    );
  }, [accountManagers, options]);

  const [isSaving, setIsSaving] = useState(false);

  const handleChange = useCallback(
    (selectedOptions) => {
      setIsSaving(true);

      const managerIds = map(selectedOptions, 'value');
      const oldManagerIds = map(managers, 'value');
      const added = difference(managerIds, oldManagerIds);
      const removed = difference(oldManagerIds, managerIds);

      setManagers(selectedOptions);

      const setAccountManager = (userId, accountManager) =>
        CloudApi.put(
          `organizations/${organizationId}/users/${userId}?validate=false`,
          { accountManager },
        );

      Promise.all(
        concat(
          added.map((id) => setAccountManager(id, true)),
          removed.map((id) => setAccountManager(id, false)),
        ),
      )
        .catch((err) => {
          window.alert(`Update Failed: ${err.message}`);
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [organizationId, managers],
  );

  return (
    <div>
      <span>Account Managers </span>

      <Select
        isMulti
        value={managers}
        onChange={handleChange}
        options={options}
        isDisabled={isSaving}
      />
    </div>
  );
};

AccountManagerDropdown.propTypes = {
  organizationId: PropTypes.string.isRequired,
  accountManagers: PropTypes.arrayOf(PropTypes.string),
};

AccountManagerDropdown.defaultProps = {
  accountManagers: [],
};

export default AccountManagerDropdown;
