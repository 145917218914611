// Copyright © 2022 Esri. All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, faUser, faBuilding } from '../Chrome/Elements/Icon';

const formatName = (name) => (name && name.trim()) || 'No Name';

const SearchResult = ({ result }) =>
  result.type === 'user' ? (
    <tr>
      <td>
        <Icon icon={faUser} />
      </td>
      <td>
        <Link to={`/users/${result.userId}`}>{formatName(result.name)}</Link>
      </td>
      <td>{result.arcgisUsername}</td>
      <td>{result.email}</td>
    </tr>
  ) : (
    <tr>
      <td>
        <Icon icon={faBuilding} />
      </td>
      <td>
        <Link to={`/organizations/${result.organizationId}`}>
          {formatName(result.name)}
        </Link>
      </td>
      <td />
      <td />
    </tr>
  );

SearchResult.propTypes = {
  result: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string,
    organizationId: PropTypes.string,
  }).isRequired,
};

export default SearchResult;
