// Copyright © 2023 Esri. All rights reserved.

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProjectInfo from '../Projects/ProjectInfo';

const ProjectsRoute = () => (
  <Switch>
    <Route
      path="/projects/:projectId"
      render={({ match }) => <ProjectInfo projectId={match.params.projectId} />}
    />
  </Switch>
);

export default ProjectsRoute;
