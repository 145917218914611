// Copyright © 2019 3D Robotics. All rights reserved.

import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import cloudApi from "../../CloudApi";
import formatError from "../../Utils/formatError";
import Button from "../Chrome/Elements/Button";
import Field from "../Chrome/Elements/Field";
import Input from "../Chrome/Elements/Input";
import SSAdminLogoFill from "../Chrome/Elements/SSAdminLogoFill";
import styles from "./Lock.module.scss";

// TODO - could change the suffix to distinguish from SSM, though they are the same from the same API
export const TOKEN_KEY = "id_token_v3";

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function deleteToken() {
  localStorage.removeItem(TOKEN_KEY);
}

function initializeSession(idToken) {
  localStorage.setItem(TOKEN_KEY, idToken);
}

class Lock extends React.Component {
  state = {
    emailText: "",
    emailError: false,
    passwordText: "",
    passwordError: false,
    error: undefined
  };

  componentDidMount() {
    // If user token is current, skip auth and redirect to the application.
    // This is only the case if a logged in user navigates to /auth. It's not the case during login flow.
    if (getToken()) {
      this.redirect();
      return;
    }
  }

  // Else if their token is not valid, show the login lock.

  redirect() {
    const previousPathname = get(this.props, 'location.state.from.pathname');
    this.props.history.replace(previousPathname ? previousPathname : "/");
  }

  validateForm = () => {
    this.setState({
      emailError: !this.state.emailText,
      passwordError: !this.state.passwordText
    });
    return this.state.emailText && this.state.passwordText;
  };

  onSubmit = event => {
    if (this.validateForm()) {
      cloudApi
        .basicPost(
          "auth/session/sitescan-admin",
          this.state.emailText,
          this.state.passwordText
        )
        .then(this.onLoginComplete, this.onLoginError);
    }
    event.preventDefault();
  };

  onLoginComplete = response => {
    this.setState({
      error: undefined
    });
    initializeSession(response.body.token);
    this.redirect();
  };

  onLoginError = err => {
    this.setState({
      error: formatError(err),
    });
  };

  render() {
    return (
      <form className={styles.container} onSubmit={this.onSubmit}>
        <div style={{ margin: '0 auto' }}>
          <SSAdminLogoFill width={150} />
        </div>

        <div className={styles.form}>
          {this.state.error && (
            <div className={styles.error}>{this.state.error}</div>
          )}

          <Field title="Email">
            <Input
              value={this.state.emailText}
              placeholder="Email"
              onChange={event =>
                this.setState({
                  emailText: event.target.value,
                  emailError: null
                })}
              className={this.state.emailError ? styles.inputError : undefined}
              type="text"
              autoComplete="off"
              autoCapitalize="off"
            />
          </Field>

          <Field title="Password">
            <Input
              value={this.state.passwordText}
              placeholder="Password"
              onChange={event =>
                this.setState({
                  passwordText: event.target.value,
                  passwordError: null
                })}
              className={
                this.state.passwordError ? styles.inputError : undefined
              }
              type="password"
              autoComplete="off"
              autoCapitalize="off"
            />
          </Field>
        </div>

        <Button className={styles.loginButton}>LOG IN</Button>
      </form>
    );
  }
}

Lock.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func
  }).isRequired
};

export default withRouter(Lock);
